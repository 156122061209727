@primary-background-color: #F2F2F2;
@plain-white         : #FFFFFF;
@primary-border      :#E0E0E0;

.notes-library-desktop {
  font-family: Crimson Pro;
  background-color: @plain-white;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .table {
    border: 1px solid @primary-border;
  }
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;