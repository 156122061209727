@import '../../App.less';

#Payments {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 26px 0px 26px 0px;

  .payment-method-text {
    font-family: Crimson Pro;
    font-size: 33px;
    line-height: 92.5%;
    font-weight: 600;
    color: @heading-color;
  }
  .display-flex{
    display: flex;
  }

  .payment-setting-align {
    padding: 28px 0px 0px 40px;
  }

  .margin-alignment {
    margin: 28px 0;
  }

  .payment-method-row {
    display: flex;
    justify-content: space-between;

    .ant-col-9 {
      max-width: 34.7%;
    }
  }

  .add-payment {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 150.19%;
    color: @primary-color;
  }

  .StripeElement {
    border: 1px solid #d9d9d9;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    height: 42px;
  }

  .StripeElement--invalid {
    border: 1px solid red;
  }

  .card-info {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bolder;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .mastercard-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 114.69%;
    color: @heading-color;
  }

  .payee-div {
    margin: 15px 13px 0px 13px;
  }

  .add-payment-divider {
    margin: 3px 0px 18px 0px;
  }

  .margin-left-12 {
    margin-left: 12px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .payee-email {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 114.69%;
    color: #000000;
  }

  .payment-method {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bolder;
    font-size: 17px;
    line-height: 150.19%;
    color: #263238;
  }

  .radio-button-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .margin-bottom-9 {
    margin-bottom: 9px;
  }

  .margin-right-7 {
    margin-right: 7px !important;
  }

  .card-info-row {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .ant-select-selector {
    border-radius: 6px;
    align-items: center;
  }

  .delete-btn {
    width: 178px !important;
    border: 1px solid @primary-color;
    color: @primary-color;
  }

  .save-btn {
    width: 178px !important;
  }


  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .text-decorate {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #62AC61;
  }

  .delete-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: @primary-color;
  }

  .margin-top-16 {
    margin-top: -16px;
  }

  .paypal-size {
    height: 60px;
    width: 60px;
  }

  .divider-margin {
    margin: 7px 0px 14px 0px;
  }

  .vertical-spacing {
    margin-top: 14px;
  }

  .edit-btn {
    width: 96px !important;
    border: 1px solid #9B9B9B;
    color: #9B9B9B;
  }

  .edit-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #9B9B9B;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 164.5%;
    color: #9B9B9B;
  }

  .ant-select-selection-placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 164.5%;
    color: @heading-color;
  }

  .ant-select-arrow {
    color: @heading-color !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #B0B0B0;
  }

  .ant-radio-inner::after {
    background-color: #62AC61;
  }

  .add-payment-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .last-divider-margin {
    margin: 30px 0px 17px 0px;
  }

}

@media only screen and (max-width: 1000px) {

  #Payments {
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
    margin: 26px 0px 26px 0px;

    .payment-method-text {
      font-family: Crimson Pro;
      font-size: 26px;
      line-height: 92.5%;
      font-weight: 600;
      color: @heading-color;
    }

    .edit-btn {
      width: 96px !important;
      border: 1px solid #9B9B9B;
      color: #9B9B9B;
      height: min-content;
      padding:0px;
    }

    .edit-btn-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 13.1008px;
      line-height: 16px;
      text-transform: capitalize;
      color: #9B9B9B;
    }

    .delete-btn {
      width: 96px !important;
      border: 1px solid @primary-color;
      color: @primary-color;
      height: min-content;
      padding:0px;
      height:31px;
    }

    .ant-btn-block {
      width: 100% !important;
    }

    .display-flex{
      display: none;
    }

    .payee-div {
      margin: 0px 0px 0px 13px;
    }
  }

}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;