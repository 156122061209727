@import '../../pages/App.less';


.innovation-text-landing {
  font-family: Crimson Pro;
  text-align : left;
  font-weight: 600;
  color      : @heading-color;
}

.crimson-font {
  font-family: "Crimson Pro";
}


.landing-button {
  font-family  : DM Sans;
  font-style   : normal;
  border-radius: 6px !important;
  font-weight  : 500;
  font-size    : 12px;
  line-height  : 18px;
  text-align   : center;
  width        : 155px;
  height       : 40px !important;
  margin-right: 10px;

  @media(max-width: @mobile-width) {
    width: 100%;
    margin-right: unset;
    margin-top: 5px;
  }
}

.landing-button-primary {
  color        : @plain-white  !important;
}

.landing-button-secondary {
  color        : @primary-color  !important;
  border       : 1px solid @primary-color  !important;
}

.why-panel-container {
  width: 200px;
  height: 200px;
  background-color: @plain-white;
  color: @heading-color;
  font-family: "Crimson Pro";

  @media (max-width: @mobile-width) {
    width: 100%;
    height: unset;
    margin-top: 10px;
  }
}

.benefits-panel-container {
  width: 100%;
  background-color: @plain-white;
  color: @grey-text;
  font-family: DM Sans;
}

.background-grey {
  background-color: @text-color-secondary;
}

.tutor-present-container {
  height: 330px;
  width: 270px;
}

.testimonial-present-container {
  height: 400px;
  width: 360px;
  background-color: @text-color-secondary;
  font-family: DM Sans;

}

.testimonial-present-name {
  font-size: 20px;
  color: @heading-color;
  font-family: Crimson Pro;
}
.testimonial-present-text {
  font-size: 14px;
  text-align: center;
  color: @grey-text;
}

.red-highlight {
  color: @primary-color;
}

.tutor-present-image {
  top: -30px;
  position: relative;
}
.tutor-present-name {
  font-family: "Crimson Pro";
  color: @heading-color;
  font-size: 18px;
  text-align: center;
}

.tutor-present-subject {
  font-family: "DM Sans";
  color: @primary-color;
  font-size: 14px;
  text-align: center;
}

.tutor-present-desc {
  font-family: "DM Sans";
  color: @heading-color;
  font-size: 14px;
  text-align: center;
}

.school-fails-image {
  width: 500px;
  @media (max-width: @mobile-width) {
    width: 100%;
  }
}
.book-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;

}

.school-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  @media(max-width: @mobile-width) {
    width: 90vw;
  }
}

.featured-tutors-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  @media(max-width: @mobile-width) {
    width: 90vw;
  }
}

.school-logo {
  max-width: 180px;
  @media(max-width: @mobile-width) {
    width: 100px;
  }
}

.school-alumni-name {
  color: @heading-color
}

.school-alumni-skill {
  color: @grey-text;
}

.book-image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 250px;
  @media (max-width: @mobile-width) {
    width: 120px;
    height: 150px;
  }
}

.book-text {
  font-size: 20px;
  font-family: "Crimson Pro";
  color: @text-color-secondary;
  position: relative;
  bottom: 70px;
  left: 30px;
  width: 150px;
  overflow: hidden;
  @media (max-width: @mobile-width) {
    left: 16px;
    width: 90px;
    font-size: 12px;
  }
}

.onetwothree {
  position: relative;
  bottom: 80px;
  left: 30px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;