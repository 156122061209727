@import '../App.less';

#MainRow {
  background: #F9F7F7 !important;
  height: 100%;

  .custom-height {
    height: 100vh;
  }

  .anticon {
    color: black
  }

}

#PayoutDesktop {
  background-color: @plain-white;
  //border: 1px solid @primary-border;
  border-radius: 10px;
  margin: 10px 0px 0px 0px;
  font-size: 22px;
  font-weight: 500;

  .payout-setting-title {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .payout-setting-align {
    padding: 20px 0px 0px 85px;
  }

  .payout-save-btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 15px;
    text-transform: capitalize;
    color: #FFFFFF;
  }

  .ant-select-selector {
    border-radius: 6px;
  }

  .form-row {
    justify-content: space-between;
  }

  .text-decorate {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    text-decoration-line: underline;
    color: @primary-color;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .ant-input {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px;
    color: #263238 !important;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .buttons-margin {
    margin: 12px 0px 0px 0px;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
  }

  .payout-info-title {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .payout-delete-btn {
    border: 1px solid @primary-color;
    color: @primary-color;
  }

  .payout-delete-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #E26F6F;
  }

  .table-name-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: black;
  }

}

@media only screen and (max-width: 600px) {

  #PayoutDesktop {
    background-color: @plain-white;
    //border: 1px solid @primary-border;
    border-radius: 10px;
    margin: 10px 0px 0px 0px;
    font-size: 22px;
    font-weight: 500;

    .payout-setting-align {
      padding: 20px 0px 0px 0px;
      text-align: center;
    }

    .ant-btn-block {
      width: 100% !important;
    }
  }


}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;