@import '../../App.less';

@primary-background-color: #F2F2F2;

#MyNotesMobile {
  display: flex;
  flex-Direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: @primary-background-color;

  .title-text {
    font-family: Crimson Pro;
    color: @heading-color;
  }

  .label-card {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .value-card {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }


  .item-text {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: @heading-color !important;
  }

}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;