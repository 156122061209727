@import '~antd/dist/antd.less';

body {
  /* background: black; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'typeface-montserrat'
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','typeface-montserrat'
    sans-serif;
}

* {
  box-sizing: border-box;
}

/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.66);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.66);
}

* {
  scrollbar-width: thin;
  scrollbar-color: hsla(0, 0%, 0%, 0.66) hsla(0, 0%, 0%, 0.66);
  scrollbar-color: dark;
} */

/* @media (prefers-color-scheme: dark) {
  html,
  body {
    background-color: hsl(240, 10%, 10%);
    color: hsl(0, 0%, 85%);
  }
} */

a {
  text-decoration: none;
  color          : hsl(0, 66%, 66%);
}

.text-center {
  text-align: center;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;