.about-main-container {
  padding: 0 24px;
}

.left-container {
  display        : flex !important;
  align-items    : center !important;
  justify-content: center !important;
  flex-direction : column !important;
}

.flex-column {
  flex-direction: column;
}

.about-bottom-container {
  padding: 70px 0;
}

.about-m-bottom-container {
  padding: 70px 12px;
}

.space-between {
  justify-content: space-between;
}

.ant-btn-lg {
  border-radius: 5px !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;