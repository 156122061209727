@import '../App.less';


.header-user {
  font-family  : Arno Pro;
  font-style   : normal;
  font-weight  : 600;
  font-size    : 18px;
  line-height  : 22px;
  padding-right: 12px;
  color        : @heading-color  !important;
}

.margin-top-12 {
  margin-top: 12px;
}

.extend-menu {
  display        : flex !important;
  justify-content: flex-end !important;
}

.link {
  font-family: crimson pro;
  font-style : normal;
  font-weight: 500;
  font-size  : 18px !important;
  line-height: 22px;
  font-weight: 600 !important;
  text-align : left !important;
  width      : 140px !important;
  height     : 30px !important;
  padding    : 2px 12px 0 0 !important;
  color      : @primary-color  !important;

  &:hover{
    color      : @heading-color  !important;
  }

}

.btn-login {
  width        : 168px !important;
  height       : 40px !important;
  border-radius: 5px !important;
  border       : 1px solid @primary-color  !important;
  margin       : 0 16px 0 0 !important;
}

.login-text {
  font-family   : Montserrat;
  font-style    : normal;
  font-weight   : bold;
  font-size     : 13px;
  line-height   : 16px;
  text-transform: capitalize;
  color         : @primary-color  !important;
}

.menu-link-text {
  font-family   : Montserrat;
  font-style    : normal;
  font-weight   : bold;
  font-size     : 13px;
  line-height   : 16px;
  text-transform: capitalize;
  color         : @text-color  !important;
}

.left-menu {
  display        : flex !important;
  justify-content: flex-start !important;
  //border: 1px solid blue;
}

.right-menu {
  //border: 1px solid blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.links {
  margin-top: 4px;

  .Links>* {
    margin: 1px 0.5em;
  }
}

.main-logo {
  padding-left: 35px !important;
}

.navbar {
  height      : 93px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  //border: 1px solid green;
}

.ant-modal-close {
  top  : -13px !important;
  right: -150px !important;
  color: @primary-color  !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;