@import '../App.less';

.main-d-container {
  margin: 10% 30%;
}

.btn-join {
  border: 1.5px solid @primary-color !important;
  color: @primary-color !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;