@import '../../App.less';

#PasswordReset {
  background-color: @plain-white;
  border          : 1px solid @primary-border;
  border-radius   : 5px;
  margin          : 10px 0px 0px 0px;

  .account-setting-align {
    padding: 20px 0px 0px 32px;
  }

  .account-setting-title {
    font-family: Crimson Pro;
    font-size  : 32px;
    line-height: 92.5%;
    margin-top : 4px;
    color      : @heading-color;
  }

  .row-height {
    height: 20px;
  }

  .form-row {
    justify-content: space-between;
  }


  .select-width {
    width        : 100%;
    border-radius: 5px;
    margin-top   : 15px;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
    width        : 100% !important;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 12px;
    color      : #263238;
  }

  .divider-margin {
    margin: 26px 0px 6px 0px;
  }
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;