@import '../App.less';

.blog-list-component {
  height: 400px;
  @media (max-width: @mobile-width) {
    height: unset;
  }
}

.blog-list-component-title {
  height: 60px;
  @media (max-width: @mobile-width) {
    height: unset;
  }
}

.blog-list-component-excerpt {
  height: 80px;
  @media (max-width: @mobile-width) {
    height: unset;
  }
}

.blog-search-box {
  input[type="text"], input[type="email"], textarea {
    background-color: @text-color-secondary;
  }

  input::placeholder {
    font-size: 14px;
    font-family: "DM Sans";
  }

  input {
    outline: none;
    font-size: 14px;
    font-family: "DM Sans";
    color: @heading-color;
  }
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;