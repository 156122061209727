@import '../App.less';


//#mobileHomePage {
//
//}
#landingPageTemplateMobile {
  button {
    width: 200px !important;
  }

  .homepage {
    height: 100vh;
    background-color: @primary-color;
  }

  .background {
    height: 92vh;
    background-color: @primary-color;
  }

  .box-container {
    background-color: white !important;
    border: 1px solid whitesmoke !important;
    padding: 20px;
    margin: 20px;
  }

  .full-width {
    min-width: 100% !important;
  }

  .section-choose-us {
    margin-top: 5em;
    padding-top: 0.2px;
    padding-bottom: 2em;
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu-links {
    height: 92vh;
  }

  .text {
    color: @text-color-secondary !important;
    font-size: 24px !important;
  }


  .ant-picker-header {
    button {
      width: unset !important;
    }
  }

  .inner-div-math-tutor {
    width: unset;
    height: unset;
    @media (max-width: 992px) {
      margin: -400px auto -60px auto !important;
    }
  }

  .first-session-align-math-tutor {
    @media (max-width: 992px) {
      margin-left: 0px !important;
      a {
        margin-top: 10px !important;
      }
    }
  }

  .back-div-math-tutor {
    width: unset;
    @media (max-width: 992px) {
      height: 480px !important;
    }
  }

  .blur {
    position: absolute;
    width: 107.24px;
    height: 132.02px;
    left: 232.64px;
    top: -29px;
    filter: blur(16px);
    background: @text-color-secondary;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .ant-typography {
    margin-bottom: 0 !important;
  }

  .subject {
    flex-direction: column !important;
    overflow-x: scroll;
    height: 150px;
    padding: 0 12px;
  }

  .tutor-from {
    margin-top: 25px;
    flex-direction: column !important;
    overflow-x: scroll;
    height: 100px;
    padding: 0 12px;
    width: 100%;
  }

  .top-earners {
    margin-top: 25px;
    flex-direction: column !important;
    overflow-x: scroll;
    height: 350px;
    padding: 0 12px;
    width: 100%;
  }

  .book {
    height: 150px;
    width: 120px;
    border-radius: 10px;
    margin-bottom: 12px;
    display: flex !important;
  }

  .university-logo {
    height: 150px;
    max-width: 300px;
    border-radius: 10px;
    margin-bottom: 12px;
    display: flex !important;
  }

  .direction {
    width: 16px !important;
    height: 16px !important;
  }

  .university-title {
    font-size: 10px !important;
  }

  .padding-48 {
    padding: 0 48px;
  }

  .margin-top-mobile {
    margin-top: 30px
  }

  .margin-bottom-70 {
    margin-bottom: 70px;
  }

  .left-box {
    flex-direction: column;
    justify-content: center;
  }

  .ready-text-mobile {
    font-family: Crimson Pro !important;
    font-size: 48px !important;
    line-height: 58px;
    font-weight: 600;
    text-align: left;
    color: @heading-color !important;
  }

  .mesg-input-mobile-text {
    margin-top: 16px !important;
    width: 100% !important;
    border: 1px solid #979797 !important;
    border-radius: 10px !important;
  }

  .mesg-input-mobile-text-area {
    margin-top: 16px !important;
    height: 101px !important;
    width: 365px !important;
    border: 1px solid #979797 !important;
    border-radius: 10px !important;
  }

  .refund-box {
    margin: 0 12px !important;
    border-radius: 6px !important;
  }

  .refund-text-mobile {
    font-family: Crimson Pro;
    font-size: 50px !important;
    line-height: normal;
    font-weight: 600 !important;
    text-align: center;
    color: @text-color-secondary !important;
  }

  .happy-text-mobile {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: normal;
    text-align: center;
    color: @text-color-secondary !important;
    margin-top: 5px;
  }

  .padding-w-12 {
    padding: 0 12px
  }

  .fails-text-mobile {
    font-family: Crimson Pro;
    font-size: 50px;
    line-height: normal;
    font-weight: 800;
    text-align: center;
    color: @heading-color;
  }

  .books-name-mobile {
    position: absolute;
    height: 0px;
    left: 23%;
    flex-direction: column-reverse;
    right: 4%;
    top: calc(12% - 32px / 2 + 160px);
    font-family: Crimson Pro;
    font-size: 15px;
    font-weight: 500;
    line-height: 34px;
    display: flex;
    color: #FFFFFF !important;
    margin-top: -13px;
  }
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;