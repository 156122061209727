@import '../App.less';

.signup-container {
  padding-top: 40%
}

.main-container {
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 6px;
  padding: 28px 13px 50px 13px;
}

.btn-join {
  border: 1.5px solid @primary-color !important;
  color: @primary-color !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;