@import '../../App.less';

@primary-background-color: #F2F2F2;

#NotesCardMobile {
  display: flex;
  flex-Direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: @primary-background-color;

  .title-text {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 21px;
    color: @primary-color;
  }

  .price-text {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 21px;
    color: @heading-color;
  }

  .info-text {
    font-family: Crimson Pro;
    margin-top: 5%;
  }

  .sold-heading-text {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-top: 5px;
    color: @heading-color !important;
  }

  .sold-text {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-top: 2px;
    color: @heading-color !important;
  }

  .details-text {
    font-family: Crimson Pro;
    font-size: 12px;
    line-height: 21px;
    font-weight: 600;
    margin-left: 5px;
    color: @text-color !important;
  }

  .divider {
    padding-top: 38px;
    height: 40px;
    color: @heading-color !important;
  }

}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;