@import '../App.less';


@primary-background-color: #F9F7F7;
@secondary-background-color: #FFFFFF;


#header-tutor {
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  background: @secondary-background-color;

  .keep-spaced {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
  }

  .arrow-align {
    margin-left: 15px;
    align-self: center;
    text-align: center;
    color: #263238;
  }

  .header-heading {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 28px;
    color: @heading-color;

  }

  .header-align {
    margin: 9px 0px 0px 30px;
  }

}

#TutorProfileDesktop {
  background: @primary-background-color !important;
  width: 100%;

  .avatar-size {
    size: 122px;
  }

  .row-height {
    height: 30px;
  }

  .service-fee-text {
    padding-top: 5px;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 600;
  }

  .location-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    padding: 4px 0 0 8px;
    color: @heading-color;
  }

  .margin-top-11 {
    margin-top: 11px;
  }

  .padding-tutor-name {
    padding: 7px 0px 0px 7px;
    margin-left: 32px;
  }

  .first-row {
    border-radius: 0px 0px 4px 4px;
    margin-left: 20px;
    width: 97%;
    background: @secondary-background-color;
  }

  .tutor-name {
    font-family: Crimson Pro;
    font-size: 36px;
    font-weight: 600;
    color: @heading-color;
  }

  .top-rated {
    padding-top: 2px;
    padding-left: 2px;
    font-family: Crimson Pro;
    font-size: 12px;
    color: @primary-color;
  }

  .badge-icon {
    height: 28px;
    width: 28px;
  }

  .width-100 {
    width: 100%
  }

  .space-between {
    margin-top: 25px;
    justify-content: space-between;
  }

  .qualification-adjustment {
    margin-top: 50px;
    justify-content: space-between;
  }

  .view-profile {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    font-weight: 600;
    color: @heading-color;
  }

  .diplomas {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150.19%;
    color: #263238;
    margin: 0px !important;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .padding-top-5 {
    padding-top: 6px;
    margin-left: 2px;
  }

  .margin-top-8 {
    margin-top: 8px;
  }

  .padding-left-6 {
    padding-left: 6px;
  }

  .tick-mark {
    height: 24px;
    width: 16px;
    margin-left: 7px;

  }

  .space-between {
    justify-content: space-between;
  }

  .width-20 {
    width: 20%;
    margin-top: 30px;
  }

  .head-font {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: #000000;
    font-weight: 600;
  }

  .text-decorate {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    text-decoration-line: underline;
    color: #E26F6F;
  }

  .second-row {
    margin-top: 16px;
    margin-left: 20px;
    width: 97%;
    background: @secondary-background-color;
  }

  .date-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: #9B9B9B;
    padding-left: 5px;
    padding-top: 3px;

  }

  .rating-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
    color: #263238;
    padding-top: 2px;
    padding-left: 7px;
  }

  .star-space-desktop {
    font-size: 13px;

    .ant-rate-star {
      margin-right: 0px !important;
      color: @primary-color;
    }
  }

  .comment-text {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    color: #263238;
  }

  .reviewer {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    color: #E26F6F;
  }

  .private {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
    color: #263238;
  }

  .width-100 {
    width: 100%;
  }

  .height-36 {
    height: 36px;
  }

  .padding-20 {
    padding: 20px 0px 0px 20px;
  }

  .padding-left-26 {
    padding-left: 26px;
  }

  .margin-neg-4 {
    margin-left: -4px;
  }

  .margin-neg-10 {
    margin-top: -10px;
  }

  .common-heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150.19%;
    color: #263238;
  }

  .common-category {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .rate-100 {
    padding-top: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .para-text {
    width: 47%;
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .read-more-button {
    text-decoration: underline;
  }

  .ant-progress-success-bg,
  .ant-progress-bg {

    border-radius: 0px !important;

  }

  .ant-progress-inner {

    border-radius: 0px !important;
    height: 4px !important;
  }

  .rating-percent {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 150.19%;
    color: #000000;
  }

  .job-success {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: @heading-color;
  }

  .dip-width {
    margin-top: 30px;
    width: 280px;
    display: flex;
    justify-content: space-between;
  }

  .ant-progress-line {
    font-size: 0px !important;
  }

  .margin-top-17 {
    margin-top: -17px;
  }

  .margin-top-6 {
    margin-top: -6px;
  }

  .anticon-right {
    font-size: 13px;
    margin-bottom: 2px;
    color: #263238;
  }

  .ant-collapse {
    border: 0px;
  }

  .ant-collapse-item {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    background: @secondary-background-color
  }

  .three-sided-border {
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    border-top: 0;
  }

  .anticon-right {
    font-size: 0px;
  }

  .para-collapse {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #263238;
  }

  .read-more-margin {
    margin-top: -15px;
  }

  .icon-specs {
    right: 60px !important;
    font-size: 24px !important;
    color: #E26F6F !important;
  }

  .ant-collapse-header {
    font-family: Crimson Pro !important;
    font-size: 32px;
    margin: 13px 0px 13px 51px;
    font-weight: 600;
    color: #000000;
  }

  .main-heading-collapase {
    font-family: Crimson Pro;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }

  .vertical-border {
    border-left: 1px solid #E0E0E0;
    margin-top: -25px;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .session-request-btn {
    border: 1px solid @primary-color;
    color: @primary-color;
    border-radius: 5px;
  }

  .session-request-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #E26F6F;
  }

  .message-btn {
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;

    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #E26F6F !important;
  }

  .text-button {
    padding: 4px 15px 4px 15px;
    color: #FFFFFF;
  }


}

@media only screen and (max-width: 600px) {
  #TutorProfileDesktop {
    background: @primary-background-color !important;
    width: 100%;
    margin-top: 8px;

    .qualification-adjustment {
      margin-top: 40px;
      justify-content: space-between;
    }

    .rate-adjustment {
      margin-top: 30px;
      text-align: center;
      justify-content: center;
    }

    .width-20 {
      width: 80%;
      margin-top: 30px;
    }

    .first-row {
      border-radius: 0px 0px 4px 4px;
      margin-left: 5px;
      width: 98%;
      background: @secondary-background-color;
    }

    .para-text {
      width: 80%;
      margin-top: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 150.19%;
      color: #263238;
    }

    .tutor-name {
      font-family: Crimson Pro;
      font-size: 36px;
      font-weight: 600;
      color: @heading-color;
    }

    .view-profile {
      font-family: Crimson Pro;
      font-size: 27px;
      line-height: 92.5%;
      font-weight: 600;
      color: @heading-color;
    }

    .textAlign {
      text-align: center;
    }

    .padding-tutor-name {
      padding: 2px 0px 0px 0px;
      margin-left: 20px;

    }

    .message-btn {
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 13.1008px;
      line-height: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #E26F6F !important;
      //background: #fff !important;
    }

    .ant-btn-block {
      width: 100% !important;
    }

    .ant-collapse-header {
      font-family: Crimson Pro !important;
      font-size: 32px;
      margin: 2px 0px 2px 15px;
      font-weight: 600;
      color: #000000;
    }

  }

}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;