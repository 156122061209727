@import '../App.less';

.table-text {
  color: @heading-color
}

.ndis-tutor-form {
  max-width : unset !important;

  .checkbox-form-text {
    color: white;
    margin-left: 8px;
  }

  .select-form-text {
    color: white;
    margin-right: 8px;
  }
  .select-form-dropdown {
    width: 100%;
    background-color: @primary-color;
    border: 1px solid @form-input-border;
    color        : @text-color-secondary;
    min-height   : 42px;
    border-radius: 6px;
    padding-top: 10px;
  }


  .ant-input {
    background   : @primary-color  !important;
    border       : 1px solid @form-input-border  !important;
    color        : @text-color-secondary  !important;
    min-height   : 42px;
    border-radius: 6px;
  }

  .ant-input::placeholder {
    color      : @text-color-secondary  !important;
    opacity    : 1;
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 12px;
    line-height: 164.5%;
    color      : @plain-white;
  }

  .ant-input:-ms-input-placeholder {
    color: @text-color-secondary  !important;
  }

  .ant-input::-ms-input-placeholder {
    color: @text-color-secondary  !important;
  }

  .ant-input:-webkit-autofill,
  .ant-input:-webkit-autofill:hover,
  .ant-input:-webkit-autofill:focus,
  .ant-input:-webkit-autofill:active {
    -webkit-text-fill-color: @text-color-secondary  !important;
    border                 : 1px solid @text-color-secondary;
    -webkit-text-fill-color: @text-color-secondary;
    -webkit-box-shadow     : 0 0 0px 1000px @primary-color inset;
    box-shadow             : 0 0 0px 1000px @primary-color inset;
  }
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;