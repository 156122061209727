@import '../App.less';

@secondary-background-color: #FFFFFF;

#header-tutor {
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  background: @secondary-background-color;

  .keep-spaced {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
  }

  .arrow-align {
    margin-left: 15px;
    align-self: center;
    text-align: center;
    color: #263238;
  }

  .header-heading {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 28px;
    color: @heading-color;

  }

  .header-align {
    margin: 9px 0px 0px 30px;
  }

}

#MainRow {
  background: @text-color-secondary;

  //.keep-spaced {
  //  display        : flex;
  //  justify-content: space-between;
  //}
}

#UpdateEventDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 35px 10px 0px 10px;
  //padding: 8px;


  .divider-margin {
    margin: 5px 0px 5px 0px;
  }

  .view-profile {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    font-weight: 600;
    color: black;
  }

  .common-heading {
    font-family: Crimson Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150.19%;
    color: #263238;
    //color      : #E26F6F;
  }

  .para-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150.19%;
    color: #263238;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
  }

  .keep-spaced {
    display: flex;
    justify-content: space-between;
  }

  .payout-save-btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 15px;
    text-transform: capitalize;
    color: #FFFFFF;
  }

  .cost {
    background: #E26F6F;
    color: white;
    padding: 10px;
    border-radius: 8px;
    row-gap: 8px;
  }

  .para-cost {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 160.19%;
    color: white;
  }

  .avatar-div-padding {
    padding: 0px 25px 0px 0px;
  }

  .icon-font-size {
    font-size: 34px;
  }

  .message-request-btn {
    border: 1px solid @primary-color;
    color: @primary-color;
  }

  .message-request-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #E26F6F;
  }

  .upload-pic-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }


  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    text-align: left;
    color: @text-color;
  }
}

@media only screen and(max-width: 600px) {
  #UpdateEventDesktop {

    .view-profile {
      font-family: Crimson Pro;
      font-size: 24px;
      line-height: 92.5%;
      color: @heading-color;
    }
    .ant-btn-block {
      width: 100% !important;
    }


  }

}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;