@import '../../App.less';

.starter {
  font-family: Crimson Pro !important;
  font-style : normal;
  font-weight: 500;
  font-size  : 18px;
  line-height: 150.19%;
  color      : @heading-color  !important;
}

.answer {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 150.19%;
  margin-top : 10px;
  color      : @heading-color  !important;
}

.row-30 {
  height: 30px;
}

.margin-14 {
  margin-left: 14px;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;