@import '../App.less';


.find-tutor-form {
  padding: 24px !important;

  .checkbox-form-text {
    color: white;
    margin-left: 8px;
  }

  .select-form-text {
    color: white;
    margin-right: 8px;
  }
  .select-form-dropdown {
    width: 100%;
    background-color: @primary-color;
    border: 1px solid @form-input-border;
    color        : @text-color-secondary;
    min-height   : 42px;
    border-radius: 6px;
    padding-top: 10px;
  }


  .ant-input {
    background   : @primary-color  !important;
    border       : 1px solid @form-input-border  !important;
    color        : @text-color-secondary  !important;
    min-height   : 42px;
    border-radius: 6px;
  }

  .ant-input::placeholder {
    color      : @text-color-secondary  !important;
    opacity    : 1;
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 12px;
    line-height: 164.5%;
    color      : @plain-white;
  }

  .ant-input:-ms-input-placeholder {
    color: @text-color-secondary  !important;
  }

  .ant-input::-ms-input-placeholder {
    color: @text-color-secondary  !important;
  }

  .ant-input:-webkit-autofill,
  .ant-input:-webkit-autofill:hover,
  .ant-input:-webkit-autofill:focus,
  .ant-input:-webkit-autofill:active {
    -webkit-text-fill-color: @text-color-secondary  !important;
    border                 : 1px solid @text-color-secondary;
    -webkit-text-fill-color: @text-color-secondary;
    -webkit-box-shadow     : 0 0 0px 1000px @primary-color inset;
    box-shadow             : 0 0 0px 1000px @primary-color inset;
  }
}

.continue-btn {
  width : 328px !important;
  height: 42px !important;
}

.condition-text {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 10px;
  line-height: 149.5%;
  color      : #F2E4E4 !important;
}

.con-underline {
  border-bottom: 1px solid @plain-white;
  color: white;
}

.con-align {
  margin: 5px 0 10px 34px;
}

.continue-btn-text {
  font-family   : Montserrat;
  font-style    : normal;
  font-weight   : bold;
  font-size     : 13.1008px;
  line-height   : 16px;
  text-transform: capitalize;
  color         : @primary-color;
}

.padding-w-12 {
  padding: 0 12px !important;
}

.align-content-center {
  justify-content: center !important;
}

.pocketnote-title {
  font-family: Crimson Pro;
  font-size  : 62px;
  line-height: 92.5%;
  font-weight: 600 !important;
  color      : @primary-color  !important;
}

.connects-title {
  font-family: Crimson Pro;
  font-size  : 60px;
  line-height: 92.5%;
  font-weight: 600 !important;
  color      : @heading-color  !important;
}

.become-tutor-form {
  font-family: Arno Pro;
  font-style : normal;
  font-weight: 600;
  font-size  : 48px;
  line-height: 58px;
  text-align : center;
  margin-top : 24px !important;
  color      : @plain-white  !important;
}

.border-radius-13 {
  border-radius: 13.1008px;
}

.margin-top-26 {
  margin-top: 26px;
}

@media (min-width: 768px) {
  .blur {
    left: 565px;
  }

  .offering {
    text-align: end !important;
  }

  .main-img-col {
    display        : flex !important;
    justify-content: center;
  }

}

.margin-top-45 {
  margin-top: 45px;
}

.online-text {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 24px;
  line-height: 29px;
  text-align : center;
  color      : @primary-color  !important;
}

.offer-text {
  font-family: Crimson Pro;
  font-size  : 65px;
  line-height: 92.5%;
  font-weight: 600;
  text-align : center;
  color      : @heading-color  !important;
}

.types-text {
  font-family: Crimson Pro;
  font-size  : 65px;
  line-height: 92.5%;
  font-weight: 600;
  text-align : center;
  color      : @primary-color  !important;
}

.info-comp {
  font-family: Crimson Pro;
  font-size  : 29px;
  line-height: 34px;
  text-align : center;
  font-weight: 600;
  color      : @primary-color;
}

.info-comp-para {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 150.19%;
  margin-top : 8px;
  text-align : center;
  color      : #9B9B9B;
}

.align-para {
  display        : contents !important;
  justify-content: center;
}

.row-85 {
  height: 85px;
}

.row-60 {
  height: 60px;
}

.row-80 {
  height: 80px;
}

.row-40 {
  height: 40px;
}

.row-120 {
  height: 120px;
}

@media (min-width: 992px) {
  .blur {
    left: 452px;
  }

  .offering {
    text-align: center !important;
  }

  .main-img-col {
    display: block !important;
  }
}

@media (min-width: 1300px) {
  .main-img {
    width: 900px !important;
  }

  .blur {
    left: 544px;
  }
}


@media (min-width: 1600px) {
  .main-img {
    width : 1200px !important;
    height: 500px !important;
  }

  .blur {
    left: 704px;
  }
}

.img-end {
  display        : flex !important;
  justify-content: flex-end;
}

.padding-8 {
  padding: 8px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;