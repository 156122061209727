@import '../App.less';


.get-started {
  font-family: Crimson Pro;
  font-size  : 18px;
  line-height: 21px;
  font-weight: 600;
  text-align : left !important;
  color      : @heading-color  !important;
}

.about-text {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 150.19%;
  text-align : left !important;
  color      : @text-color  !important;
}

.started-align {
  margin: 48px 0 0 -11px !important;
}

.pocketnote-align {
  margin: 48px 0 0 38px !important;
}

.terms-con {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 150.19%;
  color      : @heading-color  !important;
}

.margin-10 {
  margin-left: 10px;
}

.footer-row {
  height: 20px;
}

.divider-footer {
  height: 1px !important;
  color : #E0E0E0 !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;