@import '../../App.less';

@primary-background-color: #F2F2F2;

.title-text {
  font-family: Crimson Pro;
  color: @heading-color;
}

.modal-title-text {
  font-family: Crimson Pro;
  font-size: 25px!important;
  font-style: italic;
  color: @heading-color;
}

#SellNotesCard {
  display: flex;
  flex-Direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  //background-color: @primary-background-color;


  .title-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    //border: 1px solid blue;
  }

  .left-container {
    display: flex;
    flex-direction: column;

  }

  .label-text {
    font-family: Crimson Pro;
    width: 100%;

    font-size: 20px;
    line-height: 21px;
    font-weight: 600;
    margin-top: 2%;
    margin-right: 3%;
    align-self: flex-start;
    color: @heading-color !important;

  }

  .info-text {
    font-family: Crimson Pro;
    font-size: 16px;
    margin-bottom: 5%;
    align-self: flex-start;
  }

  .value-text {
    font-family: Crimson Pro;
    font-size: 20px;
    //margin-bottom: 5%;
  }

  .right-container {
    display: flex;
    flex-direction: column;
  }

  .preview-box {
    width: 60%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-content: flex-end;

    color: @plain-white;
    background-color: #D9D9D9;
    border: 1px solid #E5E5E5;
  }

  .preview-button {
    width: 90% !important;
    background-color: black;
    padding: 5px;
    border: 0;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 5%;
  }

  .purchase-box {

    width: 60%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;

    border-radius: 15px;
    background-color: @primary-color;
    border: 1px solid @primary-color;

  }

  .price-div {
    font-family: Crimson Pro;
    width: 100%;
    padding: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: @plain-white;
  }

  .purchase-button {
    width: 70% !important;

    background-color: @plain-white;
    padding: 5px;
    border: 0;
    border-radius: 10px;
    align-self: center;
    margin-bottom: 5%;

  }

  @media (max-width: 575px) {
    .purchase-box {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .preview-box {
      width: 100%;
    }
  }
}


@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;