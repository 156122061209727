@import '../App.less';

.background-primary {
  background-color: @primary-color;
}

.color-primary {
  color: @primary-color;
}

.color-secondary {
  color: @text-color-secondary;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;