@import '../../App.less';

@primary-color: #E26F6F;
@secondary-color: #9B9B9B;
@primary-background-color: #F2F2F2;
@secondary-background-color: #FFFFFF;
@popover-background-color: #F9F7F7;
@arrow-color: #263238;
@descriptive-text-color: #9B9B9B;
@bold-text-color: #263238;
@icon-color: #62AC61;


#BuyNotesMobile {
  background-color: @primary-background-color;


  .notes-price-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
  }


  .notes-column-mobile {
    height: 720px;
  }

  .align-filters-icon {
    padding-bottom: 3px;
  }

  .subject-Row-mobile {
    //text-align: ;
    width: 100%;
  }

  .mobile-search-bar {
    height: 41px !important;
    width: 96% !important;
    border-radius: 5px !important;
    border-color: @plain-white !important;

    .ant-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150.19%;
      margin: 4px 0 0 6px;
      color: #263238;
    }
  }


  .search-btn {
    margin-top: 0px !important;
    border-radius: 6px !important;
    width: 80px !important;
    height: 40px !important;
  }

  .location-main-Box-mobile {
    padding-left: 0px;
    padding-top: 2px;
  }


  .match-outer-Box-mobile {
    padding-top: 0px;
    padding-bottom: 9px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }


  .notes-main-container {
    height: 610px;
    //background: @secondary-background-color;
    border-radius: 6px;
    //margin: 0 0;
    //padding: 0 5px;
    overflow: scroll;
  }

  .color-mobile {
    background-color: @primary-color;
    height: 88px;
    width: 100%;
    padding-left: 16px;
    padding-top: 23px;
  }

  .btn-link {
    color: @secondary-color !important;

    &:hover {
      color: @primary-color !important;
    }
  }
  
  .location-icon {
    color: @primary-color !important;
    font-size: 25px;
    padding-top: 9px;
  }
  
  .btn-custom {
    max-width: 153px !important;
    height: 28px !important;
    padding-right: 93px !important;
    border-radius: 3px !important;

    .anticon {
      padding-right: 14px !important;
    }
  }

  .padding-top-71 {
    padding-top: 71px !important;
  }
  
  .font-size {
    padding-left: 2px;
    padding-top: 10px;
    font-weight: 500 !important;
    font-size: 17px !important;
  }

  .font-size-mobile {
    padding-left: 2px;
    padding-top: 3px;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding-right: 28px;
    line-height: 20px;
  }

  .filters-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    margin-left: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
  }
  
  .small-location-icon {
    padding: 8px 0 0 9px;
  }

  .icon-align-3 {
    padding-bottom: 3px;
  }


  .padding-top-3 {
    padding-top: 3px;
  }

  .notes-price-font-mobile {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150.19%;
  }

  .divider-padding {
    padding-bottom: 73px !important;
  }
}

.select {
  padding-top: 12px !important;
  width: 160px !important;

  .ant-select-selector {
    height: 29px !important;
    border-radius: 3px !important;
    border-color: @secondary-color !important;
  }

  .ant-select-arrow {
    padding-top: 6px;
    color: @arrow-color;
  }

  .ant-select-selection-item {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 150.19%;
    margin-top: -1px;
    color: #263238;
  }
}

.filter-main-box-popover {
  height: 100%;
  padding-left: 0px;
  padding-top: 24px;
}

.preferences-popover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;
}


.popover-filter {

  .slider-line-color-mobile {
    .ant-slider-track-1 {
      background-color: @primary-color;
    }
  }

  .ant-slider-handle {
    border: 1px solid #9B9B9B;
  }

  .notes-price-font-mobile {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150.19%;
  }

  .notes-price-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: #9B9B9B;
  }

  .prefs-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 227.19%;
    color: #263238;
    width: 154px;
  }

}

.btn-custom-popover {
  width: 100% !important;
  height: 39px !important;
  border-radius: 3px !important;
}
.border-width {
  width: 100%;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;