@import '../../App.less';



#MyNotesDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;


  .my-notes-title {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .my-notes-notes-align {
    padding: 20px 0px 0px 85px;
  }

}



@media only screen and (max-width: 1000px) {
  #MyNotesDesktop {
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
    margin: 10px 0px 0px 0px;

    .my-notes-title {
      font-family: Crimson Pro;
      font-size: 25px;
      line-height: 92.5%;
      text-align: center;
      color: @heading-color;
    }

    .my-notes-notes-align {
      padding: 20px 0px 0px 0px;
    }


  }

}

@media only screen and(max-width: 600px){


}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;