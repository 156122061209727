@import '../../App.less';



#header-notes {
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  background: #FFFFFF;

  .keep-spaced {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
  }


  .header-heading {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 28px;
    color: @heading-color;

  }

  .header-align {
    margin: 9px 0px 0px 30px;
  }

}

#CreateNotesDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;

  .create-notes-title {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .account-setting-align {
    padding: 20px 0px 0px 85px;
  }

  .ant-select {
    width: 100%;
    border-radius: 15px;
  }

  .contact-info-title {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .text-decorate {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    text-decoration-line: underline;
    color: @primary-color;
  }


  .upload-pic-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
  }


  .read-more-margin-notes {
    margin: -7px;
    margin-left: -2px;
  }

  .email-col {
    margin: 35px 0px 0px 0px;
  }


  .row-height {
    height: 20px;
  }

  .row-height2 {
    height: 10px;
  }

  .ant-select-selection-placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: @heading-color;
  }

  .ant-input {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px;
    color: #263238 !important;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .buttons-margin {
    margin: 12px 0px 0px 0px;

  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
  }

  .service-fee-info {
    border-radius: 5px;
    border: 2px solid #E26F6F;
    padding: 13px;
  }

  .pocketnote-description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 50%;
    color: black;
  }

  .info-col {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
  }

  .info-icon {
    color: #E26F6F;
    font-size: larger;
  }

}

#NotesDesktopCollapse {
  margin: 10px 0px 0px 0px;

  .ant-collapse {
    border-radius: 5px !important;
  }
  .days-name {
    padding-top:20px;
    font-family: Crimson Pro;
    font-size  : 20px;
    line-height: 13px;
    color      : @heading-color;
  }

  .delete-icon{
    align-items: center;
    display: inline-flex;
  }

  .ant-collapse-content {
    color: black;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-select-selector {
    border-radius: 6px;
  }

  .tabs-margin {
    margin: 18px 0px 0px 0px;
  }

  .icon-specs {
    right: 30px !important;
    font-size: 24px !important;
    color: #E26F6F !important;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin: 4px 0px 0px 0px;
  }

  .ant-tabs-tab-active {
    color: @heading-color;
  }

  .tabs-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
    color: @heading-color;
  }

  .collapse-background {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    font-weight: 600;
    border-radius: 5px !important;
    color: @heading-color;
    background: @plain-white;
  }

  .row-height {
    height: 20px;
  }

  //.row-height2 {
  //  height: 20px;
  //}


  .ant-select-selection-placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: @heading-color;
  }

  .submit-document-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150.19%;
    color: @heading-color;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
  }
}


@media only screen and (max-width: 1000px) {
  #CreateNotesDesktop {
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
    margin: 10px 0px 0px 0px;

    .create-notes-title {
      font-family: Crimson Pro;
      font-size: 25px;
      line-height: 92.5%;
      text-align: center;
      color: @heading-color;
    }

    .textAlign {
      text-align: center;
    }

    .ant-upload{
      width: 100% !important;
    }

  }

  #NotesDesktopCollapse {
    margin: 10px 0px 0px 16px;

    .textAlign {
      text-align: center;
    }
    .delete-icon{
      //align-items: center;
      display: inline-block;
    }

  }


  #VerficationCollapse {
    margin: 10px 0px 0px 16px;

    .photo-id {
      font-family: Crimson Pro;
      font-size: 16px;
      line-height: 21px;
      color: #E26F6F;
    }

  }

}

@media only screen and(max-width: 600px){

  #NotesDesktopCollapse{
    .ant-btn-block {
      width: 100% !important;
    }

    .delete-icon{
      align-items: center;
      display: none;
    }

    .delete-btn {
      border: 1px solid @primary-color;
      color: @primary-color;
    }

  }

}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;