@import '../App.less';

@selected-item-bg : #F1F1F1;
@border-color     :#E0E0E0;
@ultra-black      : #000000;
@secondary-background-color: #D9EBD9;

#TutorSessionMobile {
  background : @text-color-secondary  !important;
  width      : 100%;
  padding-top: 16px;

  .first-row {
    border-radius: 5px;
    margin-left  : 16px;
    width        : 92%;
    background   : @plain-white;
  }

  .border-main {
    border       : 1px solid @border-color;
    border-radius: 5px;
  }

  .tutor-col {
    margin-top: 11px;
  }

  .avatar-alignment {
    padding-left: 14px;
    padding-top : 6px;
  }

  .padding-tutor-name {
    padding: 7px 0px 0px 7px;
  }

  .tutor-name {
    font-family: Crimson Pro;
    font-size  : 36px;
    line-height: 92.5%;
    font-weight: 600;
    color      : @heading-color;
  }

  .location-col {
    margin-top: 8px;
  }

  .location-name-padding {
    padding-left: 6px;
  }

  .rating-row-margin {
    margin-top: 10px;
  }

  .rating-percent {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 600;
    font-size  : 21px;
    line-height: 150.19%;
    color      : @ultra-black;
  }

  .progress-margin {
    margin-top: -17px;
  }

  .job-margin {
    margin-top: -6px;
  }

  .badge-icon {
    height: 48px;
    width : 48px;
  }

  .top-rated {
    font-family: Crimson Pro;
    padding-top: 9px;
    font-size  : 20px;
    font-weight: 600;
    color      : @primary-color;
  }

  .message-btn-col {
    margin-left: 15px;
    margin-top : 10px;
  }

  .message-btn {
    font-family   : Montserrat;
    font-style    : normal;
    font-weight   : bold;
    border-radius : 5px;
    font-size     : 13.1008px;
    line-height   : 16px;
    text-align    : center;
    text-transform: capitalize;
    color         : @primary-color  !important;
    background    : @plain-white  !important;
  }

  .req-session-font {
    font-family: Crimson Pro;
    font-weight: 600;
    font-size  : 22px;
    width      : 100% !important;
    text-align : center !important;
    padding    : 0px 32px 0px 32px !important;
  }

  .req-primary-color {
    color: @heading-color;
  }

  .req-secondary-color {
    color: @primary-color;
  }

  .availabilty-text-row {
    width      : 33%;
    margin-top : 30px;
    margin-left: 10px;
  }

  .availability-font {
    font-weight: 600;
    font-family: Crimson Pro;
    font-size  : 18px;
    color      : @primary-color;
  }

  .availability-desc {
    width      : 95%;
    margin     : 15px 0px 0px 10px;
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 17px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .view-profile {
    width      : 100%;
    margin-top : 26px;
    margin-left: 10px;
  }

  .choose-time {
    font-family: Crimson Pro;
    font-size  : 24px;
    font-weight: 600;
    color      : @heading-color;
  }

  .add-sesion-div {
    margin-bottom: 8px;
  }

  .add-session {
    padding: 0px !important;
    color  : @primary-color;
  }

  .ant-btn-text {
    background: none !important;
  }

}

#TutorSession {
  background: @text-color-secondary  !important;
  width     : 100%;
  padding   : 16px 0px 16px 0px;

  .first-row {
    border-radius: 5px;
    margin-left  : 16px;
    width        : 92%;
    border       : 1px solid @primary-border;
    box-sizing   : border-box;
    background   : @plain-white;
  }

  .close-icon {
    padding      : 12px;
    font-size    : 13px;
    height       : 38px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid @primary-border  !important;
    background   : @selected-item-bg  !important;
    text-align   : left !important;
  }

  .session-col-margin {
    margin: 0px 0px 0px 15px;
  }

  .session-name {
    font-family: Montserrat;
    font-style : normal;
    margin     : 22px 0px 0px 0px;
    padding    : 0px;
    font-weight: 600;
    font-size  : 18px;
    line-height: 150.19%;
    color      : @heading-color  !important;
  }

  .clock-div {
    margin: 16px 0px 0px 16px;
  }

  .time-clock {
    color      : @heading-color;
    padding-top: 5px;
  }

  .card-date {
    font-family: Montserrat;
    font-weight: 500;
    font-size  : 12px;
    line-height: 14px;
    padding    : 0px 0px 0px 12px;
    color      : @heading-color  !important;
  }

  .select-time {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    margin     : 3px 0px 0px 32px;
    color      : @success-color;
  }

  .time-zone-div {
    margin: 0px 0px 0px 16px;
  }

  .format-div {
    margin: 4px 0px 0px 16px;
  }

  .select-format {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    color      : @heading-color;
  }

  .icon-placement {
    padding: 16px;
    color  : @heading-color  !important;
  }

  .session-details {
    font-family: Montserrat;
    font-style : normal;
    padding    : 0px;
    width      : 95% !important;
    font-weight: 600;
    font-size  : 18px;
    line-height: 150.19%;
    color      : @heading-color  !important;
  }

  .ant-btn-block {
    width: 100% !important;
  }

  .save-button {
    border-radius: 5px;
    margin       : 13px;
  }
}

.req-session-mobile-popover {
  .ant-popover-content {
    width: 395px !important;
  }

  .ant-popover-inner {
    border-radius: 6px !important;
    margin-left  : 17px !important;
  }

 
}

.req-session-timezone-mobile-popover {
 .ant-popover-content {
    Width: 414px !important;
  }

  .ant-popover-inner {
    Height    : 353px !important;
    background: @text-color-secondary;
  }
}

#ReqSessionMobilePopoverOne {

  .ant-checkbox-inner {
    width           : 20px !important;
    height          : 20px !important;
    background-color: @text-color;
    border-radius   : 3px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #E26F6F !important;
  }

  .checkbox-text {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 150.19%;
    margin     : 0px !important;
    width      : 100% !important;
    color      : @text-color;
  }

  .delete {
    border-radius: 6px !important;
    width        : 78px !important;
    height       : 23px !important;
    background   : @plain-white;
    border       : 1px solid @primary-color  !important;
  }

  .delete-font {
    font-family    : Montserrat;
    font-style     : normal;
    font-weight    : bold;
    font-size      : 10px;
    line-height    : 12px;
    text-align     : center;
    text-transform : capitalize;
    display        : flex;
    justify-content: space-around;
    color          : @primary-color  !important;
  }

  .save {
    width        : 78px !important;
    height       : 23px !important;
    border-radius: 6px !important;
    background   : @primary-color  !important;
  }

  .save-font {
    font-family    : Montserrat;
    font-style     : normal;
    font-weight    : bold;
    font-size      : 10px;
    line-height    : 12px;
    text-align     : center;
    text-transform : capitalize;
    display        : flex;
    justify-content: space-around;
    color          : @plain-white  !important;
  }

  .choose-text {
    font-family: Crimson Pro;
    font-size  : 24px;
    line-height: 92.5%;
    font-weight: 600;
    color      : @heading-color;
  }

  .btn-row {
    display        : flex;
    justify-content: flex-end;
    margin         : 30px 0 8px 0;
  }

  .checkbox-align {
    margin-top: 10px;
  }

  .choose-div {
    margin-top: 10px;
  }
}

#ReqSessionMobilePopoverTwo {

  .radio-btn-font {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    margin-top : 20px;
    width      : 100% !important;
    color      : @heading-color;
  }

  .ant-radio-inner {
    width : 19px !important;
    height: 19px !important;
    border: 1.1875px solid #B0B0B0 !important;
  }

  .ant-radio-inner::after {
    background: @success-color  !important;
    width     : 11px !important;
    height    : 11px !important;
  }

  .radio-btn-font {
    margin-left: 12px;
  }

  .radio-margin {
    height: 10px;
  }

}

#ReqSessionMobilePopoverThree {

  .notification-div {
    margin-top: 12px;
  }

  .btn-row {
    display        : flex;
    justify-content: flex-end;
    margin         : 50px 0 8px 0;
  }

  .save {
    width        : 78px !important;
    height       : 23px !important;
    border-radius: 6px !important;
    background   : @primary-color  !important;
  }

  .save-font {
    font-family    : Montserrat;
    font-style     : normal;
    font-weight    : bold;
    font-size      : 10px;
    line-height    : 12px;
    text-align     : center;
    text-transform : capitalize;
    display        : flex;
    justify-content: space-around;
    color          : @plain-white  !important;
  }

  .search-notification {
    margin-top   : 20px !important;
    height       : 34px !important;
    width        : 319px !important;
    border-radius: 5px !important;
    background   : #F1F1F1 !important;
    border       : none !important;
    border-color : @plain-white  !important;
  }

  .ant-input {
    font-family: DM Sans;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    color      : @text-color;
  }

  .ant-input::placeholder {
    font-family: DM Sans;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    color      : @text-color;
  }

  .color-mobile {
    background-color: @primary-color;
    height          : 88px;
    width           : 100%;
    padding-left    : 16px;
    padding-top     : 23px;
  }

  .radio-btn-font {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    margin     : 20px 0 0 12px;
    width      : 100% !important;
    color      : @heading-color;
  }

  .ant-radio-inner {
    width : 19px !important;
    height: 19px !important;
    border: 1.1875px solid #B0B0B0 !important;
  }

  .ant-radio-inner::after {
    background: @success-color  !important;
    width     : 11px !important;
    height    : 11px !important;
  }

  .radio-btn-text {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    margin-left: 12px;
    width      : 100% !important;
    color      : @heading-color;
  }

  .notification-font {
    font-family: Crimson Pro;
    font-size  : 24px;
    line-height: 92.5%;
    font-weight: 600;
    color      : @heading-color;
  }

  .radio-align {
    margin-top: 10px;
  }

  .ant-popover-inner {
    border-radius: 6px !important;
  }
}

#ReqSessionMobilePopoverFour {

  .select-row {
    width     : 414px;
    height    : 70px;
    background: @plain-white;
  }

  .ant-select-selection-item {
    font-family: Crimson Pro;
    font-weight: 600;
    font-size  : 22px;
    line-height: 92.5%;
    margin     : 20px 100px 0 0;
    height     : 42px;
    color      : @text-color;
  }

  .arrow-align {
    margin: 20px 0 0 15px;
  }

  .selected-time {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    color      : @ultra-black;
  }

  .earth-align {
    margin-left: 15px;
  }

  .time-row-align {
    margin-top: 20px;
  }

}

.react-calendar__tile--now {
  font-family  : Roboto !important;
  font-style   : normal;
  font-weight  : normal;
  font-size    : 12px;
  line-height  : 14px;
  border-radius: 110px;
  color        : @plain-white  !important;
  background   : @success-color  !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 14px;
  color      : @ultra-black  !important;
}

.react-calendar {
  width      : 350px !important;
  background : white;
  border     : none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__month-view__days__day--weekend {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 14px;

  color: @text-color  !important;
}

.react-calendar__month-view__days__day {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 15px;
  line-height: 14px;
  color      : @ultra-black;
  height     : 48px;
}

.react-calendar__tile--active {
  border-radius: 100px;
  background   : @secondary-background-color  !important;
  color        : @success-color ;
}


.react-calendar__month-view__weekdays__weekday {
  text-align    : center;
  text-transform: uppercase;
  font-weight   : bold;
  font-size     : 0.75em;
  font-family   : Roboto;
  font-style    : normal;
  font-weight   : normal;
  font-size     : 12px;
  line-height   : 14px;
  color         : @ultra-black;
}

.react-calendar__navigation__label__labelText {
  color: @ultra-black;
}

.react-calendar__navigation__arrow {
  color: @ultra-black;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
  text-decoration: none !important;
  border-bottom  : 0;
  cursor         : help;
}

.react-calendar__tile {
  border-radius: 100px;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;