@import '../App.less';

.header {
  font-family: Crimson Pro;
}
.justify-align-center {
  display        : flex !important;
  justify-content: center !important;
  flex-direction : column;
  height         : 100%;
}

.wide-screen {
  width: 1240px;
}

.align-center {
  display        : flex;
  justify-content: center;
  align-items: center;
}

.innovation-text {
  font-family: Crimson Pro;
  text-align : left;
  margin     : 0 !important;
  font-size  : 59px !important;
  font-weight: 600;
  line-height: 51px;
  color      : @heading-color;
}



.margin-left-12 {
  margin-left: 12px;
}

.top-margin {
  margin: 12px 0 24px 0 !important;
}

.item-div {
  width: 300px;
}

.help-section-text {
  text-align: center;
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500 !important;
  font-size  : 20px !important;
  line-height: 25px;
  max-width: 70%;
  color      : @text-color-secondary  !important;
}

.first-session-align-english-tutor {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align     : center;
  height         : 336px;
  margin-left    : 50px;
  a {
    margin-top: 30px;
  }
}

.session-col {
  height: 387px;
}

.height-row {
  height: 120px;
}

.profile-name {
  font-family: Crimson Pro;
  font-size  : 18px;
  line-height: 21px;
  text-align : left;
  color      : @heading-color;
}



.books-name {
  position      : absolute;
  height        : 45px;
  left          : 20%;
  flex-direction: column-reverse;
  right         : 4%;
  top           : calc(12% - 32px/2 + 160px);
  font-family   : Crimson Pro;
  font-size     : 21px;
  font-weight   : 600;
  line-height   : 34px;
  display       : flex;
  color         : #FFFFFF !important;
}

.become-tutor {
  font-family  : DM Sans;
  font-style   : normal;
  font-weight  : 500;
  font-size    : 14px;
  line-height  : 18px;
  border-radius: 6px !important;
  text-align   : center;
  width        : 155px !important;
  height       : 40px !important;
  color        : @primary-color  !important;
  border       : 1px solid @primary-color  !important;
}

.explore-tutor {
  font-family  : DM Sans;
  font-style   : normal;
  font-weight  : 500;
  font-size    : 14px;
  line-height  : 18px;
  border-radius: 6px !important;
  text-align   : center;
  width        : 155px !important;
  height       : 40px !important;
  color        : @primary-color  !important;
  border       : 1px solid @primary-color  !important;
  margin-top: 5px;
}

.book-tutor {
  font-family  : DM Sans;
  font-style   : normal;
  width        : 155px !important;
  height       : 40px !important;
  border-radius: 6px !important;
  font-weight  : 500;
  font-size    : 14px;
  line-height  : 18px;
  text-align   : center;
  color        : @plain-white  !important;
}

.option-heading {
  font-family: Crimson Pro;
  font-size  : 30px;
  line-height: 35px;
  font-weight: 600;
  text-align : left;
  color      : @primary-color;
}

.fails-text {
  font-family: Crimson Pro;
  font-size  : 66px;
  line-height: 61px;
  font-weight: 600;
  text-align : center;
  color      : @heading-color;
}

.landing-heading-text {
  font-size  : 50px;
  color: black;
}

.subtitle-text {
  font-family: Crimson Pro;
  text-align : center;
  font-size  : 16px;
  color: @text-color;
  line-height: unset;
}

.margin-one {
  margin: 19px 0 0 56px;
}

.margin-two {
  margin: 17px 0 0 42px;
}

.margin-three {
  margin: 6px 0 0 48px;
}

.align-btn {
  margin-top: 14px;
  align-self: flex-end;
}

.full-width {
  min-width: 100% !important;
}

.learn-btn {
  width        : 139px !important;
  height       : 40px !important;
  border-radius: 6px !important;
  // align-self   : baseline;
  background   : #FFFFFF;
}

.number-div {
  display: flex;
}

.option-text {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 25px;
  margin     : 12px 0 0 50px;
  text-align : left;
  color      : @grey-text;
}

.ready-text {
  font-family: Crimson Pro !important;
  font-size  : 60px !important;
  line-height: 58px;
  font-weight: 600;
  text-align : left;
  color      : @heading-color  !important;
}

.align-top {
  margin-top: -30px;
}

.back-div-english-tutor {
  width        : 100%;
  height       : 291px;
  margin-top   : 0px;
  border-radius: 6px;
  background   : @primary-color;
}

.inner-div-english-tutor {
  display        : flex;
  justify-content: space-between;
  width          : 100%;
  margin         : -337px auto -70px auto;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-right-14 {
  margin-right: 14px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-8 {
  padding: 0 8px;
}

.padding-48 {
  padding: 0 48px;
}

.pointer {cursor: pointer;}

.subject-d {
  height        : 240px;
  margin-left   : -150px !important;
  flex-direction: column !important;
  overflow-x    : scroll;
  padding       : 0 12px;
}

.section-choose-us {
  margin-top: 5em;
  padding-top: 0.2px;
  padding-bottom: 2em;
  padding-left: 10px;
  padding-right: 10px;
}

.box-container {
  background-color: white !important;
  border: 1px solid  whitesmoke !important;
  padding: 20px;
  margin: 20px;
}

.book-d {
  position      : absolute;
  height        : 0px;
  left          : 23%;
  flex-direction: column-reverse;
  right         : 4%;
  top           : calc(12% - 32px/2 + 160px);
  font-family   : Crimson Pro;
  font-size     : 15px;
  font-weight   : 500;
  line-height   : 34px;
  display       : flex;
  color         : #FFFFFF !important;
  margin-top:-15px;
}

.flex-column {
  flex-direction: column;
}

.left-box {
  flex-direction : column;
  justify-content: center;
}

.margin-left-4 {
  margin-left: 4px;
}

.arrow-right {
  margin-left: 60%;
}

.align-arrow {
  width          : 100%;
  height         : 24%;
  display        : flex;
  justify-content: center;
  align-content  : center;
}

.margin-w-156 {
  margin: 0 156px !important;
}

.refund-text {
  font-family: Crimson Pro;
  font-size  : 66px !important;
  line-height: 62px !important;
  font-weight: 600 !important;
  margin-top : 60px;
  text-align : left;
  color      : @text-color-secondary  !important;
}

.happy-text {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500 !important;
  font-size  : 14px !important;
  line-height: 25px;
  text-align : left;
  color      : @text-color-secondary  !important;
}

.margin-happy {
  margin-top: 20px !important;
}

.main-wrapper {
  width: 600px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.school-wrapper {
  display    : flex;
  overflow-x : scroll;
  margin-left: 38px;
  height     : 80px;
}

.school-wrapper::-webkit-scrollbar {
  display: none;
}

.margin-l-50 {
  margin-left: 50px;
}

.margin-left-40 {
  margin-left: 40px;
}

.tutor-from-text {
  font-family  : Crimson Pro !important;
  font-size    : 66px;
  line-height  : 61px;
  font-weight  : 600;
  margin-bottom: 10px;
  color        : @heading-color;
}

.university-text {
  font-family: Crimson Pro !important;
  font-size  : 30px !important;
  line-height: 35px;
  text-align : left;
  margin     : 0 !important;
  color      : @primary-color  !important;
}

.school-wrapper::-webkit-scrollbar {
  width : 4px;
  height: 6px;
}

.school-wrapper::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 6px;
}

.school-wrapper::-webkit-scrollbar-thumb {
  background   : @text-color;
  border-radius: 6px;
}

.ready-div {
  justify-content: center;
}

.text-input {
  left         : 53.95%;
  right        : 0%;
  top          : 27.61%;
  bottom       : 26.49%;
  border       : 1px solid #979797;
  box-sizing   : border-box;
  border-radius: 10px;
}

.any-questions {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 25px;
  text-align : left;
  color      : @grey-text;
}

.margin-b-85 {
  margin-bottom: 85px;
}

.send-btn {
  max-width    : 170px !important;
  height       : 40px !important;
  border-radius: 5px !important;
  margin-top   : 12px;
}

.mesg-input-text {
  margin-top      : 16px !important;
  width           : 365px !important;
  border          : 1px solid #979797 !important;
  background-color: #f9f7f7 !important;
  border-radius   : 10px !important;
}

.mesg-input-text-area {
  margin-top      : 16px !important;
  height          : 101px !important;
  width           : 365px !important;
  border          : 1px solid #979797 !important;
  background-color: #f9f7f7 !important;
  border-radius   : 10px !important;
}

.learn-btn-text {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 18px;
  text-align : center;
  color      : #E26F6F;
}

.input-des {
  left       : 53.95%;
  right      : 8.01%;
  top        : 0%;
  bottom     : 83.96%;
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 25px;
  color      : @grey-text;
}

.connect-text {
  font-family: DM Sans !important;
  text-align : left;
  margin-top : 32px;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 25px;
  color      : @grey-text !important;
}

.send-btn-text {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 18px;
  text-align : center;
  color      : #FFFFFF;
}

.set-button {
  align-self: end !important;
  margin-top: 20px;
}

.set-text-div {
  margin-left: 34%;
}

.set-send-button {
  text-align: end;
}

.subject-d::-webkit-scrollbar {
  display: none;
}

.bottom-cta-title {
  font-family: Crimson Pro;
  font-style : normal;
  font-size  : 30px;
  line-height: 44px;
  text-align : center;
  margin-top : 24px !important;
  color      : @plain-white  !important;
}

.schools-tab {
  margin-left: 45px !important;

  //.ant-tabs-ink-bar {
  //  height    : 8px !important;
  //  //background: @heading-color  !important;
  //}

  .ant-tabs-nav-operations {
    display: none !important;
  }

}

.img-right-align {
  width : 16px !important;
  height: 16px !important;
  margin: 15px 0 0 0 !important;
}

.profile-des {
  font-family: DM Sans;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 17px;
  width      : 253px;
  text-align : left;
  color      : @grey-text;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;