@import '../App.less';

@screen-background         :#F1F1F1;

#FaqsMobile {

  background: @screen-background;

  .main-das {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;
  }

  .faq-heading {
    font-family: crimson Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 22px;
    line-height: 35px;
    margin     : 39px 0 0 35px;
    color      : @heading-color;
  }

  .faq-div {
    margin       : 15px 0 15px 15px;
    background   : @plain-white  !important;
    border       : 1px solid @primary-border;
    box-sizing   : border-box;
    border-radius: 6px;
  }

  .text-width {
    margin-left: 14px;
  }

  .conv-col-overflow {
    height         : 525px;
    overflow-y     : scroll;
    scroll-behavior: smooth;
  }

  .text-div {
    margin-top: 30px;
    width     : auto;
    height    : fit-content;
  }

  .ant-list-item {
    display: block !important;
  }

  .main-heading-collapase {
    font-family: Crimson Pro;
    font-size  : 20px !important;
    color      : #000000;
    font-weight: 600;
  }

  .anchor-align {
    display: contents;
  }

  .background-collapse {
    background: @plain-white;
  }

  .icon-specs-mobile {
    right    : 10px !important;
    font-size: 26px !important;
    color    : @primary-color !important;
  }

  .first-row {
    margin-left: 16px;
    width      : 92%;
    background : @plain-white;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  .faq-des {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    margin-left: 25px;
    display    : flex;
    color      : @heading-color;
  }

  .faq-text {
    font-family: crimson Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 28px;
    line-height: 150.19%;
    margin-left: 25px;
    color      : @heading-color;
  }

  .ant-collapse-header {
    border-radius: 6px !important;
  }

}

.conv-col-overflow::-webkit-scrollbar {
  width: 5px;
}

.conv-col-overflow::-webkit-scrollbar-thumb {
  background   : @primary-color;
  border-radius: 5px;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;