@import '../../App.less';

@screen-background    :#F1F1F1;

#PrivacyMobile {

  background: @screen-background;

  .main-das {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;
  }

  .privacy-col {
    margin       : 15px 0 15px 15px;
    background   : @plain-white;
    border       : 1px solid @primary-border;
    box-sizing   : border-box;
    border-radius: 6px;
  }

  .pri-policy-text {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 29px;
    line-height: 35px;
    margin     : 10px 0 0 10px;
    color      : @heading-color;
  }

  .terms-text {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 600;
    font-size  : 18px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .download-btn {
    font-family   : Montserrat;
    font-style    : normal;
    font-weight   : bold;
    font-size     : 10px;
    line-height   : 12px;
    margin        : 0 12px 0 0;
    text-align    : center;
    text-transform: capitalize;
    color         : @plain-white;
    width         : 78px !important;
    border-radius : 6px;
  }

  .text-width {
    margin-left: 14px;
  }

  .headings {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 12px;
    line-height: 16px;
    color      : @heading-color;
  }

  .heading-text {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: bold;
    font-size  : 14px;
    line-height: 16px;
    color      : @primary-color  !important;
  }

  .index {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 600;
    font-size  : 12px;
    line-height: 29px;
    color      : @heading-color;
  }

  .content {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 24px;
    line-height: 29px;
    color      : @heading-color;
  }

  .conv-col-overflow {
    height         : 525px;
    overflow-y     : scroll;
    scroll-behavior: smooth;
  }

  .text-div {
    margin-top: 30px;
    width     : auto;
    height    : fit-content;
  }

  .bullet-text {
    font-family  : Montserrat;
    font-style   : normal;
    font-weight  : 600;
    font-size    : 12px;
    line-height  : 150.19%;
    margin-top   : -35px;
    padding-right: 4px;
    color        : @heading-color;
  }

  .ant-list-item {
    display: block !important;
  }

  .description {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .policy-content {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    color      : @heading-color  !important
  }

  .sub-policy-content {
    font-family : Montserrat;
    font-style  : normal;
    font-weight : 500;
    font-size   : 13px;
    line-height : 150.19%;
    padding-left: 15px;
    color       : @heading-color  !important
  }
  
  .ordered-list {
    padding: 12px 0 0 18px !important;
  }

  .ant-list-item {
    border: 0px !important;
  }
}

.conv-col-overflow::-webkit-scrollbar {
  width: 5px;
}

.conv-col-overflow::-webkit-scrollbar-thumb {
  background   : @primary-color;
  border-radius: 5px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;