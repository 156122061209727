@import '../App.less';

@primary-background-color  : #F2F2F2;
@secondary-background-color: #D9EBD9;
@ultra-black               : #000000;
@selected-item-bg          : #F1F1F1;

#TutorSessionDesktop {
  background : @text-color-secondary  !important;
  width      : 100%;
  height     : 760px;
  padding-top: 16px;

  .margin-top-20 {
    margin-top     : 20px;
    justify-content: space-between;
    display        : flex;
    width          : 80%;
  }

  .first-row {
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left  : 104px;
    width        : 70%;
    background   : @plain-white;
  }

  .cost-font {
    font-family: Crimson Pro;
    font-size  : 31.5714px;
    line-height: 37px;
    text-align : center;
    margin-top : 33px;
    color      : @plain-white;
  }

  .cost-elements {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 12.2778px;
    line-height: 150.19%;
    color      : @plain-white;
  }

  .total-text {
    font-weight: 800 !important;
  }

  .btn-center {
    justify-content: center;
  }

  .cost-row {
    margin       : 0px 15px 0px 20px;
    height       : 288px;
    width        : 280px;
    border-radius: 10px;
    background   : @primary-color;
  }

  .top-rated {
    padding-top: 2px;
    font-family: Crimson Pro;
    font-size  : 12px;
    font-weight: 600;
    color      : @primary-color;
  }

  .badge-icon {
    height: 30px;
    width : 30px;
  }

  .choose-time-div {
    margin-top: 20px;
  }

  .choose-time {
    font-family: Crimson Pro;
    font-size  : 22px;
    font-weight: 600;
    color      : @ultra-black;
  }

  .badge-icon-align {
    display    : flex;
    padding-top: 6px;
  }

  .avatar-alignment {
    margin-left: 40px;
  }

  .border-right {
    border-right: 1px solid @primary-border;
  }

  .margin-20 {
    margin: 20px;
  }


  .rating-percent {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 600;
    font-size  : 21px;
    line-height: 150.19%;
    color      : @ultra-black;
  }

  .job-success {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 15px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .margin-top-17 {
    margin-top: -17px;
  }

  .margin-top-6 {
    margin-top: -6px;
  }

  .message-btn {
    margin        : 20px 0px 0px 8px !important;
    height        : 40px !important;
    width         : 209px !important;
    border-radius : 5px;
    font-family   : Montserrat;
    font-style    : normal;
    font-weight   : bold;
    font-size     : 13.1008px;
    line-height   : 16px;
    text-align    : center;
    text-transform: capitalize;
    color         : @primary-color  !important;
    background    : @plain-white  !important;
  }

  .req-button {
    Width        : 193.81px !important;
    Height       : 35.08px !important;
    border-radius: 5.2619px;
    background   : @plain-white;
  }

  .req-btn-text {
    font-family   : Montserrat;
    font-style    : normal;
    font-weight   : 600 !important;
    font-size     : 12px;
    line-height   : 14px;
    text-align    : center;
    text-transform: capitalize;
    color         : @primary-color;
  }

  .border-main {
    border       : 1px solid @primary-border;
    border-radius: 5px;
  }

  .border-cost {
    border       : 1px solid @primary-color;
    border-radius: 15px;
  }

  .req-session-font {
    font-family: Crimson Pro;
    font-weight: 600;
    font-size  : 25px;
    width      : 100% !important;
    text-align : left !important;
    color      : @heading-color  !important;
    padding    : 0px 32px 0px 32px !important;
  }

  .subject-row {
    margin: 16px 0px 0px -5px;
  }

  .subject-style {
    border       : 1px solid @text-color;
    border-radius: 15px;
    margin       : 3px;
  }

  .subject-font {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 164.5%;
    margin     : 12px;
    color      : @text-color;
  }

  .req-secondary-color {
    color: @primary-color;
  }

  .availability-font {
    font-family: Crimson Pro;
    font-size  : 18px;
    line-height: 21px;
    font-weight: 600;
    color      : @primary-color;
  }

  .availability-para {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .add-session {
    color      : @primary-color;
    padding    : 0px !important;
  }

  .padding-26 {
    padding: 26px 0px 0px 26px;
  }

  .margin-both-24 {
    margin: 24px 0px 0px 24px;
  }

  .color-white {
    border-color: @primary-background-color;
  }

  .background-color-40 {
    background: @primary-border  !important;
  }
}

#SessionDesktopPopover {
  .card-date {
    font-family: Montserrat !important;
    font-style : normal;
    font-weight: normal;
    font-size  : 12px;
    line-height: 14px;
    color      : @heading-color  !important;
  }

  .row-flex {
    flex-direction: row-reverse !important;
  }

  .padding-16 {
    padding: 16px;
    color  : @heading-color  !important;
  }

  .clock {
    padding: 0 16px 0 16px;
  }

  .ant-input {
    color: @heading-color  !important;
  }

  .session-name {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 600;
    font-size  : 18px;
    line-height: 150.19%;
    padding    : 0px !important;
    margin-top : 15px !important;
    width      : 95% !important;
    color      : @heading-color  !important;
  }

  .btn-color {
    font-family  : Roboto;
    font-style   : normal;
    font-weight  : normal;
    line-height  : 14px;
    border-radius: 6px;
    height       : 22px !important;
    width        : 61px !important;
    font-size    : 12px !important;
    width        : 18% !important;
    padding      : 0px !important;
    margin       : 6px 0 0 16px !important;
    color        : @heading-color  !important;
  }

  .btn-color:focus {
    color     : @success-color  !important;
    background: #D9EBD9 !important;
  }

  .reminder-align {
    margin: 6px 0 0 32px !important;
  }

  .time-zone {
    font-family: Roboto;
    font-style : normal;
    font-weight: normal;
    font-size  : 12px;
    line-height: 14px;
    color      : @heading-color  !important;
  }

  .close-icon {
    font-size    : 13px;
    height       : 42px;
    border-radius: 5px 5px 0px 0px;
    text-align   : right !important;
    padding      : 14px 10px 12px 10px;
    background   : @primary-border  !important;
  }

  .first-selection {
    font-family: Montserrat;
    font-style : normal;
    font-weight: normal;
    width      : 171px !important;
    font-size  : 12px !important;
    line-height: 14px;
    margin-left: 10px !important;
    color      : @success-color  !important;
  }

  .selection {
    font-family: Montserrat;
    font-style : normal;
    font-weight: normal;
    font-size  : 12px !important;
    line-height: 14px;
    color      : @success-color  !important;
  }
}

.ant-select-item-option-selected {
  background: @selected-item-bg  !important;
}

.ant-select-dropdown {
  width        : 171px !important;
  border-radius: 6px !important;
}

.popover-session {
  Width : 384px !important;
  Height: 353px !important;

  .ant-popover-inner {
    border-radius: 8px !important;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
  }

  .save-btn {
    font-family   : Montserrat;
    font-style    : normal;
    font-weight   : bold;
    font-size     : 11.4892px;
    line-height   : 14px;
    text-align    : center;
    text-transform: capitalize;
    color         : @plain-white;
  }

  .save-btn-align {
    Width        : 132px !important;
    Height       : 35px !important;
    border-radius: 5.2619px;
    margin       : 13px;
    background   : @primary-color  !important;
  }

  .clock-align {
    margin-top: 7px !important;
  }
}

.time-font {
  line-height: 14px;
  width      : 144px;
  font-family: Roboto;
  font-style : normal;
  font-weight: 400 !important;
  font-size  : 13px !important;
  width      : 171px !important;
  color      : @heading-color  !important;
}

.range-font {
  line-height: 14px;
  font-family: Roboto;
  font-style : normal;
  width      : 168px;
  font-weight: 400 !important;
  font-size  : 13px !important;
  width      : 171px !important;
  color      : @heading-color  !important;
}

.react-calendar__tile--now {
  font-family  : Roboto !important;
  font-style   : normal;
  font-weight  : normal;
  font-size    : 12px;
  line-height  : 14px;
  border-radius: 110px;
  color        : @plain-white  !important;
  background   : @success-color  !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 14px;
  color      : @ultra-black  !important;
}

.react-calendar {
  width      : 350px !important;
  background : white;
  border     : none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__month-view__days__day--weekend {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 12px;
  line-height: 14px;

  color: @text-color  !important;
}

.react-calendar__month-view__days__day {
  font-family: Roboto;
  font-style : normal;
  font-weight: normal;
  font-size  : 15px;
  line-height: 14px;
  color      : @ultra-black;
  height     : 48px;
}

.react-calendar__tile--active {
  border-radius: 100px;
  background   : @secondary-background-color  !important;
  color        : @success-color ;
}


.react-calendar__month-view__weekdays__weekday {
  text-align    : center;
  text-transform: uppercase;
  font-weight   : bold;
  font-size     : 0.75em;
  font-family   : Roboto;
  font-style    : normal;
  font-weight   : normal;
  font-size     : 12px;
  line-height   : 14px;
  color         : @ultra-black;
}

.react-calendar__navigation__label__labelText {
  color: @ultra-black;
}

.react-calendar__navigation__arrow {
  color: @ultra-black;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
  text-decoration: none !important;
  border-bottom  : 0;
  cursor         : help;
}

.react-calendar__tile {
  border-radius: 100px;
}

.ant-progress-success-bg,
.ant-progress-bg {
  border-radius: 0px !important;
}

.ant-progress-inner {
  border-radius: 0px !important;
  height       : 3px !important;
}

hr {
  width       : 43%;
  border      : 1px dotted;
  border-style: dotted;
  align-self  : flex-end;
  color       : @plain-white;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;