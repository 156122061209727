@import '../../App.less';

@screen-background :#F1F1F1;

#FaqsDesktop {

  background: @screen-background;

  .terms {
    margin-top: 30px;
  }

  .faq-text {
    font-family: crimson Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 28px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .terms-text {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 16px;
    line-height: 150.19%;
    color      : @heading-color;
  }


  .faq-col {
    margin       : 30px 0 30px 0;
    background   : @plain-white;
    border       : 1px solid @primary-border;
    box-sizing   : border-box;
    border-radius: 6px;
  }

  .main-faq {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;
  }

  .def-heading {
    font-family: crimson Pro;
    font-style : normal;
    font-weight: 500;
    font-size  : 24px;
    line-height: 35px;
    margin     : 0 0 0 12px;
    color      : @heading-color;
  }

  .faq-heading {
    font-family: crimson Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 29px;
    line-height: 35px;
    margin     : 39px 0 0 35px;
    color      : @heading-color;
  }

  .text-div {
    margin: 30px 0 0 22px;
    width : auto;
    height: fit-content;
  }

  .conv-col-overflow {
    height         : 525px;
    overflow-y     : scroll;
    scroll-behavior: smooth;
  }

  .text-width {
    margin-left: 14px;
  }

  .heading-text {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 18px;
    line-height: 16px;
    color      : @primary-color  !important;
  }

  .headings {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 17px;
    line-height: 16px;
    color      : @primary-color  !important;
  }

  .bullet-text {
    font-family  : Montserrat;
    font-style   : normal;
    font-weight  : 600;
    font-size    : 13px;
    line-height  : 150.19%;
    margin-top   : -20px;
    padding-right: 4px;
    color        : @heading-color;
  }

  .main-bullet {
    font-family  : Montserrat;
    font-style   : normal;
    font-weight  : 600;
    font-size    : 14px;
    line-height  : 150.19%;
    margin-top   : -25px;
    padding-right: 4px;
    color        : @heading-color  !important;
  }

  .main-description {
    font-family: Crimson pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 18px;
    line-height: 150.19%;
    margin-left: 4px;
    color      : @heading-color;
  }

  .main-bullet-ab {
    font-family: Montserrat !important;
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 150.19%;
    margin-top : -15px;
    margin-left: 0px;
    padding    : 0px !important;
    color      : @heading-color  !important;
  }

  .main-description-ab {
    font-family: Crimson pro !important;
    font-style : normal;
    font-weight: 500;
    font-size  : 16px;
    line-height: 150.19%;
    margin-left: 6px;
    color      : @heading-color;

    .ant-list-item {
      padding: 4px !important;
    }
  }

  .description {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    margin-left: 8px;
    color      : @heading-color;
  }

  .margin-bullet {
    margin-left: 12px;
  }

 

  .ant-list-item {
    border: 0px !important;
  }

  .ant-list-item {
    display: block !important;
  }
}

.conv-col-overflow::-webkit-scrollbar {
  width: 5px;
}

.conv-col-overflow::-webkit-scrollbar-thumb {
  background   : @primary-color;
  border-radius: 5px;
}

.ab {
  .ant-list-item {
    padding: 4px !important;
  }
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;