@import '../../App.less';

@screen-background    :#F1F1F1;

#PrivacyDesktop {

  background: @screen-background;

  .terms {
    margin-top: 30px;
  }

  .main-das {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    align-items    : center;
  }

  .download-button {
    width        : 183px !important;
    height       : 40px !important;
    border-radius: 6px !important;
    margin       : 39px 35px 0 0 !important;
  }

  .privacy-col {
    margin       : 30px 0 30px 0;
    background   : @plain-white;
    border       : 1px solid @primary-border;
    box-sizing   : border-box;
    border-radius: 6px;
  }

  .pri-policy-text {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 29px;
    line-height: 35px;
    margin     : 39px 0 0 35px;
    color      : @heading-color;
  }

  .terms-text {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 16px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .text-width {
    margin-left: 14px;
  }

  .heading-text {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 16px;
    color      : @primary-color  !important;
  }

  .headings {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 16px;
    color      : @heading-color;
  }

  .content {
    font-family: Arno Pro;
    font-style : normal;
    font-weight: 600;
    font-size  : 24px;
    line-height: 29px;
    color      : @heading-color;
  }

  .conv-col-overflow {
    height         : 525px;
    overflow-y     : scroll;
    scroll-behavior: smooth;
  }

  .text-div {
    margin: 30px 0 0 22px;
    width : auto;
    height: fit-content;

  }

  .ant-list-item {
    display: block !important;
  }

  .description {
    font-family: Montserrat;
    font-style : normal;
    font-weight: 500;
    font-size  : 13px;
    line-height: 150.19%;
    color      : @heading-color;
  }

  .bullet-text {
    font-family  : Montserrat;
    font-style   : normal;
    font-weight  : 600;
    font-size    : 13px;
    line-height  : 150.19%;
    margin-top   : -35px;
    padding-right: 4px;
    color        : @heading-color;
  }

  .ordered-list {
    padding: 12px 0 0 18px !important;
  }

  .ant-list-item {
    border: 0px !important;
  }
}

.conv-col-overflow::-webkit-scrollbar {
  width: 5px;
}

.conv-col-overflow::-webkit-scrollbar-thumb {
  background   : @primary-color;
  border-radius: 5px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;