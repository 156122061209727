@import '../App.less';


#MainRow {
  background: @text-color-secondary;
}

#EventsDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 35px 0px 25px 0px;


  .req-block {
    background-color: @plain-white;
    border: 1px solid @success-color;
    border-radius: 5px;
  }

  .req-block-border {
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
  }

  .accept-btn {
    margin: 30px 5px 0px 0px !important;
    width: 186px !important;
    align-self: center;
  }

  .events-history-overflow {
    height: 360px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .outlined-margin {
    margin-top: 40px;
  }

  .divider-margin {
    margin: 5px 0px 5px 0px;
  }

  .main-title {
    font-family: Crimson Pro;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #E26F6F;
  }

  .main-title-margin {
    margin: 0px 0px 0px 32px;
  }

  .sesion-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    color: #9B9B9B;
  }

  .desc-session {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: #263238;
  }

  .space-flex {
    display: flex;
    justify-content: space-between;
  }
  .space-flex-header{
    display: flex;
    justify-content: space-between;
  }

  .event-setting-title {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .event-setting-align {
    padding: 20px 0px 0px 35px;
  }

  .accept-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
  }

  .row-height {
    height: 20px;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin: 4px 0px 0px 0px;
  }

  .ant-input {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px;
    color: #263238 !important;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .recurring-text {
    color: @primary-color;
    font-weight: bolder;
    font-family: 'Crimson Pro';
  }

  .recurring-text-col {
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
  }

  .cancel-recurring {
    border: 1px solid @primary-color;
    color: @primary-color;
    max-width: 105px;
  }

  .cancel-recurring-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #E26F6F;
    border-radius: 5px !important;
  }

}

.events-history-overflow::-webkit-scrollbar {
  width: 5px;
}

.events-history-overflow::-webkit-scrollbar-thumb {
  background: @primary-color;
  border-radius: 5px;
}

@media only screen and(max-width: 600px) {
  #EventsDesktop {
    .event-setting-align {
      padding: 20px 10px 0px 15px;
      justify-content: center;
    }
    .css-jtkwu7{
      padding:15px
    }

    .event-setting-title {
      font-family: Crimson Pro;
      font-size: 24px;
      line-height: 92.5%;
      color: @heading-color;
    }

    .recurring-text-col {
      display: none;
      align-content: flex-end;
      flex-wrap: wrap;
    }

    .events-history-overflow {
      height: 430px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      overflow-x: hidden;
    }
    .space-flex-header{
      display: none;
      justify-content: space-between;
    }
    .text-align{
      text-align: center;
    }
    recurring-text {
      color: @primary-color;
      font-weight: bolder;
      font-family: 'Crimson Pro';
      font-size: 24px;
      line-height: 92.5%;

    }
    .row-height-recurring{
      height: 10px;
    }


  }


}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;