@import '../../App.less';

@primary-background-color: #F2F2F2;

#YourNotesMobile {
  display: flex;
  flex-Direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 15px;
  padding: 0 10px;
  height: 100%;
  background-color: @primary-background-color;

  .title-text {
    font-family: Crimson Pro;
    color: @heading-color;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .label-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
  }

  .item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: @heading-color !important;
  }

  .view-notes-button {
    width: 100% !important;
    border-radius: 5px;
  }

}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;