@import '~antd/dist/antd.css';
@import "~tailwindcss/base.css";
@import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";

@primary-color       : #E26F6F;
@font-size-base      : 16px;
@heading-color       : #263238;
@text-color          : #9B9B9B;
@text-color-secondary: #F9F7F7;
@success-color       : #62AC61;
@plain-white         : #FFFFFF;
@link-color          : #0872CE;
@primary-border      :#E0E0E0;
@form-input-border   :#E9EAEC;
@grey-text           :#848484;

@mobile-width:  640px;

.secondary-text-dmsans {
  color: @text-color;
  font-family: "DM Sans";
}

.heading-background {
  background-color: @heading-color;
}

.grey-text {
  color: @grey-text;
}

.border-bottom-1 {
  border-bottom: @primary-border solid 1px;
}

.box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.btn-primary {
  border-color: @text-color-secondary  !important;
}

.btn-secondary {
  color        : @primary-color  !important;
  border-radius: 6px !important;
}

.btn-sp {
  color       : @primary-color  !important;
  border-color: @primary-color  !important;
}

.btn-sm {
  max-width    : 126px !important;
  height       : 40px !important;
  border-radius: 5px !important;
}

.btn-md {
  max-width    : 170px !important;
  height       : 40px !important;
  border-radius: 5px !important;
}

.primary-text {
  color: @primary-color  !important;
}

.secondary-text {
  color: @text-color-secondary  !important;
}

.heading-text {
  color: @heading-color  !important;
}

.link-text {
  color: @link-color  !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-36 {
  margin-top: 36px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.padding-bottom-36 {
  padding-bottom: 36px !important;
}

.padding-bottom-48 {
  padding-bottom: 48px !important;
}

.background-primary {
  background-color: @primary-color  !important;
}

.background-secondary {
  background-color: @text-color-secondary  !important;
}

.background-plain {
  background-color: @plain-white  !important;
}

.no-margin {
  margin: 0 !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100vh !important;
}

.align-item-center {
  align-items: center !important;
  display    : flex !important;
}

.spaced-item {
  display        : flex !important;
  justify-content: space-between !important;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.img-center {
  display        : flex !important;
  justify-content: center;
}

.img-right {
  display        : flex !important;
  justify-content: flex-end;
}

.flex {
  display: flex !important;
}



.btn-round {
  border-radius: 7px;
}

.font {
  font-family: "Crimson Pro";
  font-size  : 66px;
  line-height: 61px;
  color      : #263238;
}

.text-left {
  text-align: webkit-left;
}

.font-size-66 {
  font-family: Crimson Pro;
  font-size  : 66px !important;
  line-height: 61px;
  color      : @heading-color  !important;
}

.message-input-box {
  height          : 28% !important;
  margin-top      : 3% !important;
  border          : 1px solid #979797 !important;
  background-color: @text-color-secondary  !important;
  box-sizing      : border-box !important;
  border-radius   : 10px !important;
}

.set-col {
  margin-top: -2% !important;
}

.set-text {
  margin-left: -13% !important;
}

.menu-link-text {
  font-family: Montserrat;
  font-style : normal;
  font-weight: 500;
  font-size  : 14px;
  line-height: 150.19%;
  margin-left: 20px !important;
  color      : @text-color;

  &:hover {
    color        : @primary-color;
    border-bottom: 1px solid @primary-color;
  }

}

.margin-left-10 {
  margin-left: 10px;
}

.clicked {
  color        : @primary-color  !important;
  border-bottom: 1px solid @primary-color  !important;
}

.ant-btn-lg {
  margin-top: 5px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;