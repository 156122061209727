@import '../../App.less';



#GettingStartedDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;

  .getting-started-title {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    color: @heading-color;
  }

  .getting-started-notes-align {
    padding: 20px 0px 0px 85px;
  }

  .faq-title {
    padding: 20px 0;
  }

  .steps-title {
    font-family: Crimson Pro;
    font-size: 20px;
    line-height: 92.5%;
    color: @heading-color;
    font-weight: bolder;
    //text-transform: uppercase;
  }


  .ant-select-selection-placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: @heading-color;
  }

}



@media only screen and (max-width: 1000px) {
  #GettingStartedDesktop {
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
    margin: 10px 0px 0px 0px;

    .getting-started-title {
      font-family: Crimson Pro;
      font-size: 25px;
      line-height: 92.5%;
      text-align: center;
      color: @heading-color;
    }

    .getting-started-notes-align {
      padding: 20px 0px 0px 0px;
    }


    .buttons-margin {
      margin: 12px 0px 0px 0px;
      text-align: center;
    }

    .textAlign {
      text-align: center;
    }

    .ant-btn-block {
      width: 100% !important;
    }

    .ant-upload{
      width: 100% !important;
    }

  }

}

@media only screen and(max-width: 600px){


}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;