@import '../App.less';

#MainRow {
  background: @text-color-secondary;

  .keep-spaced {
    display: flex;
    justify-content: space-between;
  }
}

#MessagesDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 35px 0px 0px 0px;

  .divider-margin {
    margin: 5px 0px 5px 0px;
  }

  .keep-spaced {
    display: flex;
    justify-content: space-between;
  }

  .search-btn {
    margin-top: 0px !important;
    border-radius: 6px !important;
    width: 80px !important;
    height: 32px !important;
  }

  .tutor-name {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 28px;
    color: @heading-color;
  }

  .margin-conversation {
    margin: 0px 0px 0px 25px;
    height: 100%;
  }

  .margin-conv-row {
    margin: 0px 0px 20px 0px;
  }

  .align-time {
    margin-left: 15px;
    padding-top: 3px;
  }

  .margin-row-20 {
    margin: 20px 0px 20px 0px;
  }

  .name-align {
    margin: 9px 0px 0px 30px;
  }

  .people-name {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: @heading-color;
  }

  .people-name-align {
    margin: 8px 0px 0px 7px;
  }

  .time-align {
    margin: 8px 10px 0px 0px;
  }

  .dot-btn {
    width: 40px !important;
    margin: 0px !important;
    //padding: 9px 0px 0px 0px;
  }

  .dot-btn-bottom {
    width: 40px !important;
    margin: 0px !important;
    padding: 0px 0px 3px 0px;
  }

  .time {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    text-align: right;
    color: @text-color;
  }

  .text-mesg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: @heading-color;
  }

  .conv-col-overflow {
    height: 360px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .conv-overflow {
    height: 451px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .text-mesg-align {
    width: 75%;
    margin: 4px 0px 0px 7px;
  }

  .avatar-align {
    margin: 7px 0px 7px 14px;
  }

  .conversation {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: @heading-color;
  }

  .search-icon-padding {
    padding: 14px 20px 0px 20px;
  }

  .avatar-div-padding {
    padding: 0px 25px 0px 0px;
  }

  .icon-font-size {
    font-size: 34px;
  }


  .number {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10.2857px;
    line-height: 150.19%;
    color: @plain-white;
  }

  .conversation-name {
    font-family: Crimson Pro;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    color: @heading-color;
  }

  .margin-20 {
    margin: 19px 22px 19px 14px;
  }

  .search-bar {
    .ant-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150.19%;
      margin-left: 12px;
      color: @heading-color;
    }

    padding: 0px !important;
  }

  .notification {
    background: @primary-color;
    opacity: 1.11;
    margin: 4px 10px 0px 0px;
    height: 16px;
    width: 16px;
    border-radius: 100px;
  }

  .notification-row {
    background-color: fade(@primary-color, 20%);
  }

  .number-alignment {
    margin: -6px 0px 0px 4px;
  }

  .row-height {
    height: 20px;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .back {
    background: rgba(224, 224, 224, 0.22);
  }

  .align-dots {
    margin-left: 9px;
  }

  .write-msg-input {
    border-radius: 6px;

    .ant-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150.19%;
      color: @heading-color;
    }
  }

  .conv-col-align {
    margin: 0px 0px 0px 10px;
  }

  .mic-align {
    margin: 0px 5px 0px 14px;
    padding: 2px 0px 0px 6px;
  }

  .error-text {
    color: #E26F6F;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    text-align: left;
    color: @text-color;
  }

  .conv-overflow::-webkit-scrollbar {
    display: none;
  }

  .text-width {
    width: 75%;
  }
}

// .ant-popover-inner {
//   border-radius: 6px !important;
//   border       : 1px solid @primary-border;
// }

// .ant-popover {
//   width: 17.5%;
// }

.popover-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150.19%;
  text-align: center;
  color: @text-color;
}

.row-height {
  height: 20px;
}

.ant-col-7 {
  max-width: 27.166667% !important;
}

.ant-col-3 {
  max-width: 11.5% !important;
}

.ant-col-4 {
  max-width: 15% !important;
}

.ant-col-6 {
  max-width: 23% !important;
}

.margin-12 {
  margin: 12px 0px 12px 0px;
}

.margin-5 {
  margin: 5px 0px 5px 0px;
}

.conv-col-overflow::-webkit-scrollbar {
  width: 5px;
}

.conv-col-overflow::-webkit-scrollbar-thumb {
  //background   : @primary-color;
  border-radius: 5px;
}

@media only screen and(max-width: 600px) {


  #MessagesDesktop {
    height: 94%;
    background-color: @plain-white;
    border: 1px solid @primary-border;
    border-radius: 5px;
    margin: 35px 0px 0px 0px;

    .align-dots {
      margin-left: 1px;
    }

    .dot-button {
      width: 2px;
    }
    .arrow-align {
      margin-left: 15px;
      align-self: center;
      text-align: center;
    }

    .name-align {
      margin: 9px 0px 0px 0px;
    }

    .conv-col-overflow {
      height: 530px;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .conv-overflow {
      height: 620px;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .search-btn {
      margin-top: 0px !important;
      margin-left: 5px;
      border-radius: 6px !important;
      width: 70px !important;
      height: 32px !important;
    }

  }
}

@media only screen and(max-width: 600px) {
  #MessagesDesktop {
    .icon-font-size {
      font-size: 28px;
    }


    .dot-btn {
      width: 40px !important;
      margin: 0px !important;
      //padding: 9px 0px 0px 0px;
    }

    .dot-btn-mobile {
      margin-top: 0px !important;
      //border-radius: 6px !important;
      width: 25px !important;
      //height: 32px !important;
    }


  }

}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;