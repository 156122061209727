@import '../App.less';

.tutor-card {
  width: 230px;
  height: 350px;
  margin: 0 6px;
  border-radius: 5px;
  font-family: Crimson Pro;

}

.avatar-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.name-and-price {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-evenly;

}

.badges-container {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
}

.tutor-info {
  text-align: center;

  overflow-wrap: break-word !important;
}

.view-profile-btn {
  font-family   : Montserrat;
  font-style    : normal;
  font-weight   : bold;
  border-radius : 5px !important;
  font-size     : 13.1008px;
  text-align    : center;
  text-transform: capitalize;
  color         : @plain-white !important;
  background    : @primary-color !important;
  width: 100% !important;
  align-self: center !important;
}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;