@secondary-background-color: #FFFFFF;


#bottom-navigation {
  border-radius: 0px 0px 2px 4px;
  width: 100%;
  background: @secondary-background-color;
  z-index:30;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column-reverse;
  //flex-wrap:wrap;

  .keep-spaced {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    text-align:center;
  }

  .arrow-align {
    margin-left: 15px;
    align-self: center;
    text-align: center;
    color: #263238;
  }

  .header-heading {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 28px;
    color: @heading-color;

  }

  .header-align {
    margin: 9px 0px 0px 30px;
  }

  .title-heading{
    color: #9B9B9B;
    font-family: Crimson Pro;
    font-size: 17px;
    line-height: 20px;
  }

}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;