@import '../App.less';

#DashboardDesktop {
  background-color: @plain-white;
  border: 1px solid @primary-border;
  border-radius: 5px;
  margin: 10px 0px 10px 8px;

  .star-space-desktop {
    font-size: 13px;

    .ant-rate-star {
      margin-right: 0px !important;
    }
  }
  .color-primary {
    color: #E26F6F
  }
  .para-text {
    width: 47%;
    margin-top: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    color: #263238;
  }

  .info-circle-styles {
    color: black;
    font-size: 20px;
    margin-top: 12px;
    cursor: pointer;
    margin-left: 10px
  }

  .sub-headings {
    font-family: Crimson Pro;
    font-size: 32px;
    line-height: 92.5%;
    font-weight: 600;
    color: @heading-color;
  }

  .tutor-name {
    font-family: Crimson Pro;
    font-size: 36px;
    font-weight: 600;
    color: @heading-color;
  }

  .row-height {
    height: 20px;
  }

  ::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #263238;
  }

  .ant-btn-lg {
    border-radius: 5px !important;
  }

  .margin-top-15 {
    margin-top: 15px
  }

  .delete-btn {
    border: 1px solid @primary-color;
    color: @primary-color;
  }

  .user-outline-icon {
    color: black;
    font-size: 40px;
    margin-top: 5px;
  }

  .star-size {
    font-size: 16px !important;

    .ant-rate-star {
      margin-right: 0px !important
    }
  }

  .location-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150.19%;
    padding: 4px 0 0 8px;
    color: @heading-color;
  }

  .total-rating {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    margin: 2px;
    color: @text-color;
  }

  .pause-profile-btn {
    font-weight: 600;
    display: flex;
    margin-top: 11px;
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 150.19%;
    padding: 4px 0 0 8px;
    color: @heading-color;

  }


  .total-earnings{
    margin-left: 15px;
    font-size: 70px;
    font-stretch: ultra-condensed;
  }

  .common-heading {
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150.19%;
    color: #263238;
  }

  .delete-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: #E26F6F;
  }

  .margin-left-15 {
    margin-left: 15px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }


}

@media only screen and (max-width: 600px) {

  #DashboardDesktop {


    .para-text {
      width: 80%;
      margin-top: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 150.19%;
      color: #263238;
    }

    .common-heading {
      width: 100%;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150.19%;
      color: #263238;
    }

    .align-center{
      text-align:center;
    }

    .tutor-name {
      font-family: Crimson Pro;
      font-size: 36px;
      font-weight: 600;
      color: @heading-color;
    }

    .margin-top-15 {
      margin-top: 0px;
    }

    .mobile-margin-top {
      margin-top: 10px;
    }

    .mobile-margin-left {
      margin-left: 12px
    }

    .sub-headings {
      font-family: Crimson Pro;
      font-size: 27px;
      line-height: 92.5%;
      font-weight: 600;
      color: @heading-color;
    }

    .ant-btn-block {
      width: 100% !important;
    }

    .margin-left-20 {
      margin-left: 26px;
    }

    .info-circle-styles {
      color: black;
      font-size: 20px;
      margin-top: 10px;
      cursor: pointer;
      margin-left: 10px
    }


  }


}
@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;