@import '../App.less';

.text-spacing {
  height: 26px;
}

.privacy-policy {
  color: @heading-color !important;
}

.margin-top-36 {
  margin-top: 36px !important;
}

.margin-top-48 {
  margin-top: 48px !important;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;