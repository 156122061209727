@import '../App.less';

.border-radius-6 {
  border-radius: 6px;
}
.padding-top-verified{
   padding:45px 20px 0px 20px;
 }

.mobile-form {
  .ant-input {
    background: @primary-color !important;
    border: 1px solid @text-color-secondary !important;
    color: @text-color-secondary !important;
    min-height: 40px;
  }

  .border-radius{
    border-radius: 5px !important;
  }

  
  .ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @text-color-secondary !important;
    opacity: 1; /* Firefox */
  }
  
  .ant-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: @text-color-secondary !important;
  }
  
  .ant-input::-ms-input-placeholder { /* Microsoft Edge */
    color: @text-color-secondary !important;
  }
  
  .ant-input:-webkit-autofill,
  .ant-input:-webkit-autofill:hover, 
  .ant-input:-webkit-autofill:focus, 
  .ant-input:-webkit-autofill:active
  {
    -webkit-text-fill-color: @text-color-secondary  !important;
    border: 1px solid @text-color-secondary;
    -webkit-text-fill-color: @text-color-secondary;
    -webkit-box-shadow: 0 0 0px 1000px @primary-color  inset;
  }  
}

.error {
  border: 1px solid @primary-color;
  border-radius: 3px;
  padding: 0.5rem;
  margin-bottom: 3px;
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;