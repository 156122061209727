@import '../App.less';

@primary-color: #E26F6F;
@secondary-color: #9B9B9B;
@primary-background-color: #F2F2F2;
@secondary-background-color: #FFFFFF;
@popover-background-color: #F9F7F7;
@arrow-color: #263238;
@descriptive-text-color: #9B9B9B;
@bold-text-color: #263238;
@icon-color: #62AC61;


#ExploreMobile {
  background-color: @primary-background-color;


  .filter-title {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    font-size: 33px !important;
    line-height: 46px !important;
    color: #263238;
  }

  .hourly-rate-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
  }
  .serve-fee-text{
   font-size: 8px;
    font-weight: 500;
  }


  .availability-box {
    height: 220px;
  }


  .ant-rate-star {
    margin: 0px !important;
  }

  .rating-margin {
    margin-left: 15px;
  }

  .offer-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: #9B9B9B;
  }

  .lessons-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150.19%;
    color: @heading-color;
  }


  .tutor-column-mobile {
    height: 720px;
  }

  .filters-icon-align {
    padding-bottom: 3px;
  }

  .subject-row-mobile {
    //text-align: ;
    width: 100%;
  }

  .search-bar-mobile {
    height: 41px !important;
    width: 96% !important;
    border-radius: 5px !important;
    border-color: @plain-white !important;

    .ant-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150.19%;
      margin: 4px 0 0 6px;
      color: #263238;
    }
  }

  .number-rating {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    padding-left: 5px;
    color: #9B9B9B;
  }

  .total-rating {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150.19%;
    color: #263238;
  }

  .search-btn {
    margin-top: 0px !important;
    border-radius: 6px !important;
    width: 80px !important;
    height: 40px !important;
  }

  .location-main-box-mobile {
    padding-left: 0px;
    padding-top: 2px;
  }

  .result-number-text {
    font-family: Crimson Pro;
    font-size: 25px !important;
    line-height: 46px !important;
  }

  .styled-location-icon-container {
    display: flex;
    padding: 15px;
    border-bottom: 2px;

    p {
      margin: 5px !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .padding-left-227px {
    padding-left: 227px;
  }

  .loction-text {
    padding-top: 3px;
    padding-left: 8px;
  }

  .match-outer-box-mobile {
    padding-top: 0px;
    padding-bottom: 9px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .sort-text {
    padding-right: 10px;
    padding-top: 2px;
    font-family: Montserrat;
    font-weight: 600;
  }

  .tutor-name {
    font-family: Crimson Pro;
    font-size: 20px;
    line-height: 21px;
    font-weight: 600;
    color: @heading-color;
  }

  .km-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150.19%;
    padding: 8px 0 0 1px;
    color: #E26F6F;
  }

  .tutors-outer-container {
    min-height: 214px;
    background: @secondary-background-color;
    border-radius: 6px;
    margin: 0 5px;
    padding: 0 5px;
  }
  .tutor-container-height{
    height: 10px;
  }



  .ant-select-selector .checkbox-width-100-desktop {
    width: 100%;
  }
  .ant-select-selector {
    border-radius: 6px;
    height: 40px;
  }

  .ant-select-selection-item {
    padding-top: 4px;
  }

  .tutor-main-container {
    height: 610px;
    //background: @secondary-background-color;
    border-radius: 6px;
    //margin: 0 0;
    //padding: 0 5px;
    overflow: scroll;
  }

  .color-mobile {
    background-color: @primary-color;
    height: 88px;
    width: 100%;
    padding-left: 16px;
    padding-top: 23px;
  }

  .btn-link {
    color: @secondary-color !important;

    &:hover {
      color: @primary-color !important;
    }
  }

  .result-container-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .location-icon {
    color: @primary-color !important;
    font-size: 25px;
    padding-top: 9px;
  }


  .width-online {
    width: 100%
  }

  .btn-custom {
    max-width: 153px !important;
    height: 28px !important;
    padding-right: 93px !important;
    border-radius: 3px !important;

    .anticon {
      padding-right: 14px !important;
    }
  }


  .divider-margin {
    margin-left: 12px;
  }

  .per-hour-rate {
    font-family: Crimson Pro;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: #263238;
  }

  .padding-top-71 {
    padding-top: 71px !important;
  }

  .modal-container {
    background: rgba(0, 0, 0, 0.5)
  }


  .avatar-specs {
    width: 36%
  }

  .location-icon-desktop {
    color: @primary-color !important;
    font-size: 30px;
    padding-top: 9px;
  }

  .font-size {
    padding-left: 2px;
    padding-top: 10px;
    font-weight: 500 !important;
    font-size: 17px !important;
  }

  .font-size-mobile {
    padding-left: 2px;
    padding-top: 3px;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding-right: 28px;
    line-height: 20px;
  }

  .tutor-detail {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
    margin-top: 8px;
    color: #E26F6F;
  }

  .filters-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    margin-left: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
  }


  .small-location-icon {
    padding: 8px 0 0 9px;
  }

  .para-width {
    padding-left: 16px;
  }

  .avatar-div-mobile {
    padding-top: 15px;
    padding-left: 11px;
    justify-content: space-between;
  }

  .discriptive-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: @descriptive-text-color;
  }

  .rate-font-size-mobile {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  .text-appo {
    padding-left: 3px;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 150.19% !important;

    color: @bold-text-color !important;
  }

  .text-oppo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;

    color: @descriptive-text-color;
  }

  .padding-top-33 {
    padding-top: 33px;
    padding-right: 49px;

  }

  .rate-space {
    padding-left: 4px
  }

  .out-of-padding-mobile {
    padding-top: 1px;
  }

  .work-hour-div {
    margin-left: 2px;
  }

  .clock-align-18 {
    padding-bottom: 18px;
  }

  .space-between {

    justify-content: space-between;
  }

  .star-space {
    font-size: 13px !important;
  }

  .offer-align-7 {
    padding-left: 7px;
  }

  .offer-align-5 {
    padding-left: 5px;
  }

  .laptop-align-3 {
    padding-bottom: 3px;
  }


  .padding-top-3 {
    padding-top: 3px;
  }

  .hourly-rate-font-mobile {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150.19%;
  }

  .divider-padding {
    padding-bottom: 73px !important;
  }
}

.select {
  padding-top: 12px !important;
  width: 160px !important;

  .ant-select-selector {
    height: 29px !important;
    border-radius: 3px !important;
    border-color: @secondary-color !important;
  }

  .ant-select-arrow {
    padding-top: 6px;
    color: @arrow-color;
  }

  .ant-select-selection-item {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 150.19%;
    margin-top: -1px;
    color: #263238;
  }
}

.filter-main-box-popover {
  height: 100%;
  padding-left: 0px;
  padding-top: 24px;
}

.preferences-popover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;

  .ant-select-selector {
    padding-right: 3px;
  }
}


.popover-filter {

  .ant-popover {
    top: 0 !important;
    left: 0 !important;
  }

  .ant-popover-title {
    font-family: Crimson Pro;
    font-size: 24px;
    line-height: 92.5%;
    font-weight: 600;
    height: 61px !important;
    padding-top: 20px !important;
    color: #263238;
  }

  .ant-popover-content {
    width: 330px !important;
  }

  .ant-popover-inner-content {
    background: @popover-background-color;
    color: @arrow-color;
  }

  .availability-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150.19%;
    color: #263238;
  }

  .ant-checkbox-inner {
    background-color: #9B9B9B;
    border-radius: 3px;
  }

  .ant-checkbox + span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    color: #9B9B9B;
  }

  .slider-line-color-mobile {
    .ant-slider-track-1 {
      background-color: @primary-color;
    }
  }

  .ant-slider-handle {
    border: 1px solid #9B9B9B;
  }

  .hourly-rate-font-mobile {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150.19%;
  }

  .hourly-rate-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150.19%;
    color: #9B9B9B;
  }

  .prefs-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 227.19%;
    color: #263238;
    width: 154px;
  }

}

.btn-custom-popover {
  width: 100% !important;
  height: 39px !important;
  border-radius: 3px !important;
}
.border-width {
  width: 100%;

  .ant-select-selector {
    border: 1px solid @text-color;
    border-radius: 6px;
    height: 100%;
    max-width: 194px;
  }

  .ant-select-selection-item {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    margin-right: 8px;
    color: @heading-color;
  }
  .ant-select-arrow {
    color: @heading-color;
  }
}

@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;