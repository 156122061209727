@import '../../App.less';

@primary-background-color: #F2F2F2;

.notes-title-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150.19%;
  color: @primary-color;
}

.sell-price-font {
  font-family: Crimson Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: @heading-color;
}

.sold-count {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 0 3px 0 3px;
  line-height: 150.19%;
  color: @heading-color;
}

#BuyNotesDesktop {
  display: flex;
  flex-Direction: column;
  height: 100%;
  background-color: @primary-background-color;

  .notes-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: @primary-color;
  }

  .ant-pagination-item-link {
    width: 60px !important;
    margin-top: 0px !important;
    border-radius: 4px;
  }

  .ant-select-selector {
    border-radius: 4px;
  }

  .pagination-styles {
    text-align: center;
  }

  .location-icon {
    width: 24px;
    height: 24px;
  }

  .location-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    margin-left: 6px;
    align-self: center;
    color: @heading-color;
  }

  .location-align {
    display: flex;
    align-items: center;
    padding-top: 16px;
  }

  .location-icon-margin {
    margin-left: 10px;
  }


  .price-font {
    font-family: Crimson Pro;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: @heading-color;
  }

  .select-width {
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
  }

  .notes-search-btn {
    width: 168px !important;
    height: 40px !important;
    margin-top: 36px !important;
    border-radius: 6px !important;
    background: @primary-color;
  }

  .notes-search-btn-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13.1008px;
    line-height: 16px;
    text-transform: capitalize;
    color: @plain-white;
  }

  .icon-align-3 {
    padding-bottom: 3px;
  }

  .offer-font {
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    margin-left: 4px;
    color: @text-color !important;
  }

  .clock-align {
    align-self: baseline;
    margin: 4px 5px 0 0;
  }

  .padding-top-33-desktop {
    padding-top: 33px;
    padding-right: 45px;

  }

  // ................

  .filter-main-box-desktop-buy-notes {
    height: 100%;
    padding-top: 24px;
  }

  .filter-Title-desktop {
    font-family: Crimson Pro;
    font-size: 33px !important;
    line-height: 46px !important;
  }


  .notes-price-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    color: @text-color;
  }

  .heading-font {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.19%;
    color: @heading-color;
  }

  .slider-line-color {
    .ant-slider-track-1 {
      background-color: @primary-color;
    }
  }


  .preferences-notes {
    width: 243px;
    min-height: 64px;
    left: 146px;
    top: 473px;
  }

  .notes-column-desktop {
    padding-left: 40px;
  }

  .subject-Row-desktop {
    display: flex;
    width: 100%;
    padding-left: 0px;
    padding-top: 24px;
    justify-content: space-between;
  }

  .notes-search-bar-desktop {
    border: 1px solid @text-color;
    margin-top: 1px !important;
    border-radius: 6px !important;
    height: 39px !important;

    .ant-input {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150.19%;
      margin: 4px 0 0 6px;
      color: @heading-color;
    }

  }

  .space-top {
    margin-top: 15px;
  }

  .input-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150.19%;
    margin: 4px 0 0 6px;
    color: @heading-color;
  }


  .location-box-desktop {
    display: flex;
    height: 8%;
    width: 100%;
    padding-left: 6px;
    padding-top: 15px;
    justify-content: space-between;
  }

  .padding-left-227px-desktop {
    padding-left: 227px;
  }

  .match-outer-box-desktop {
    padding-top: 15px;
  }

  .notes-outer-container-desktop {
    height: 700px;
    background: @plain-white;
    border-radius: 6px;
    margin-top: 30px;
    overflow: scroll;
  }

  .border-width {
    width: 100%;

    .ant-select-selector {
      border: 1px solid @text-color;
      border-radius: 6px;
      height: 100%;
      max-width: 196px;
    }

    .ant-select-selection-item {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150.19%;
      margin: 8px 0 8px 0;
      color: @heading-color;
    }
  }

  .ant-select-arrow {
    color: @heading-color;
  }

  .location-icon-cct {
    color: @primary-color !important;
    font-size: 16px;
    padding-top: 6px;
  }


  .vertical-divider {
    padding-top: 38px;
    padding-left: 14px;
    height: 17%;

    .ant-divider-vertical {
      height: 100%;
    }
  }


  .padding-top-4px {
    padding-top: 4px;
  }

}

@media only screen and(max-width: 600px) {
  #BuyNotesDesktop {
    .filter-main-box-desktop-buy-notes {
      display: none;
    }
  }

}



@primary-color: #E26F6F;@font-size-base: 16px;@heading-color: #263238;@text-color: #9B9B9B;@text-color-secondary: #F9F7F7;@plain-white: #FFFFFF;@link-color: #0872CE;